import React, { Suspense } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faTimes } from '@fortawesome/free-solid-svg-icons'
import { OliverLogo } from './OliverIcons';
import Seguro from '../Views/Seguro';

const PetProfile = React.lazy(() => import('../Views/PetProfile'));
const NewPet = React.lazy(() => import('../Views/NewPet'));
const UserAccount = React.lazy(() => import('../Views/UserAccount'));
const NewAddress = React.lazy(() => import('../Views/NewAddress'));

export default class HomeDrawerGeneric extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      content: this.props.content ? this.props.content : null
    };
  }


  componentWillReceiveProps(next_props) {

    // Cambio de content
    if (next_props.content !== this.props.content) {
      this.setState({ content: next_props.content });
    }

  }

  showContent = () => {
    if (this.props.content === 'myAccount') {
      return <Suspense fallback={<div />}><UserAccount drawer={true} data={this.props.data} toggleDrawer={this.props.toggleDrawer} /></Suspense>;
    } else if (this.props.content === 'dire') {
      return <Suspense fallback={<div />}><NewAddress data={this.props.data} showModalGeneric={this.props.showModalGeneric} /></Suspense>;
    } else if (this.props.content === 'pet') {
      return <Suspense fallback={<div />}><NewPet drawer={true} toggleDrawer={this.props.toggleDrawer} /></Suspense>;
    } else if (this.props.content === 'petProfile') {
      return <Suspense fallback={<div />}><PetProfile drawer={true} data={this.props.data} toggleDrawer={this.props.toggleDrawer} /></Suspense>;
    } else if (this.props.content === 'seguro') {
      return <Suspense fallback={<div />}><Seguro drawer={true} data={this.props.data} toggleDrawer={this.props.toggleDrawer} toggleModal={this.props.toggleModal} /></Suspense>;
    } else {
      return <Suspense fallback={<div />}></Suspense>;
    }
  }

  render() {
    return (
      <section className={this.props.showTheDrawerGeneric ? 'DrawerGeneric ShowElement' : 'DrawerGeneric'}>

        {this.props.showTheDrawerGeneric && <span className='CloseDrawer' onClick={() => this.props.toggleDrawer()} />}

        <nav className='RowCenter SpaceBetween'>
          <div className='Btn-CloseDrawer' onClick={() => this.props.toggleDrawer()} >
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <div className='DrawerLogoBox'>
            <OliverLogo />
          </div>
          <div className='Btn-CloseDrawer Invisible'>
            <FontAwesomeIcon icon={faArrowLeft} />
          </div>
        </nav>
        <div className='DrawerBox'>
          {this.showContent()}
        </div>
      </section>
    )
  }
}