// ****** REAL TIME DATA BASE ****** //

// Firebase
import firebase from 'firebase';

// =========== CONFIGURACION ===========
/*
const firebaseConfig = {
    apiKey: "AIzaSyCXGnLI6cIzBm1ympPru2y2K8jLBPCNMcg",
    authDomain: "mispichos-2aa58.firebaseapp.com",
    databaseURL: "https://mispichos-2aa58.firebaseio.com",
    projectId: "mispichos-2aa58",
    storageBucket: "mispichos-2aa58.appspot.com",
};
*/

var firebaseConfig = {
    apiKey: "AIzaSyCXGnLI6cIzBm1ympPru2y2K8jLBPCNMcg",
    authDomain: "mispichos-2aa58.firebaseapp.com",
    databaseURL: "https://mispichos-2aa58.firebaseio.com",
    projectId: "mispichos-2aa58",
    storageBucket: "mispichos-2aa58.appspot.com",
    messagingSenderId: "739198489978",
    appId: "1:739198489978:web:b9edbc8ed49ad8c02772c3",
    measurementId: "G-EYGXBSG7GQ"
};

const enviroment = process.env.REACT_APP_ENVIROMENT ? process.env.REACT_APP_ENVIROMENT : "DEV";
const envPrefi = enviroment === "DEV" ? "DEV_" : "";

// Validar firebase App
if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

// =========== FUNCIONES ===========

// FIND ALL
const findAll = async (collection) => {

    // Collection 
    let mycollection = envPrefi + collection + '/';

    let ref = firebase.database().ref(mycollection);
    let response = [];
    ref.once('value', (snapshot) => {
        snapshot.forEach((childSnapshot) => {
            response.push({
                id: childSnapshot.key,
                data: childSnapshot.val()
            })
        });
    })
    return response;
}

// FIND ONE
const findOne = async (collection, firebaseId) => {

    // Collection 
    let mycollection = envPrefi + collection + '/';

    let ref = firebase.database().ref(mycollection + firebaseId);
    return ref.once('value').then(snapshot => {
        let response = {
            id: snapshot.key,
            data: snapshot.val()
        }
        return response
    })
}

// CREATE
const create = (collection, data) => {

    // Collection 
    let mycollection = envPrefi + collection + '/';

    let aux = data;
    aux.created_at = Date();

    var url = firebase.database().ref(mycollection).push(aux);

    return url;
}

// UPDATE
const update = (collection, firebaseId, data) => {

    // Collection 
    let mycollection = envPrefi + collection + '/';

    let aux = data;
    aux.created_at = Date();

    console.log("FIREABSE ID:", firebaseId)

    firebase.database().ref(mycollection + firebaseId).set(aux);

    return true;
}

// Get ID by URL
const getFirebaseIdByUrl = (collection, url) => {
    let firebaseId = url.replace(`${firebaseConfig.databaseURL}/${envPrefi}${collection}/`, '')
    return firebaseId;
}

// ----- ----- ANALYTIC ----- -----

const sendToAnalyticsEvent = (event) => {

    // Event 
    let myevent = envPrefi + event;

    console.log("REGISTRAMOS EVENTO: ", myevent)

    const analytics = firebase.analytics();
    analytics.logEvent(myevent);
}

export { findAll, findOne, create, update, getFirebaseIdByUrl, sendToAnalyticsEvent };