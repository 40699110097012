//import axios from 'axios';

// Public Key
const country = process.env.REACT_APP_MP_COUNTRY;
var public_key = null;
var enviroment = process.env.REACT_APP_ENVIROMENT ? process.env.REACT_APP_ENVIROMENT : "DEV";

if (country === 'MLA') public_key = enviroment === "PROD" ? "APP_USR-fe5cc958-5630-4bbd-886a-7e78e039ceaf" : "TEST-2125f1b7-e02e-437c-b9b1-d30cf8a3d0ae";
if (country === 'MLM') public_key = enviroment === "PROD" ? "APP_USR-4af00629-8ccb-4105-b165-ba132a3b2266" : "TEST-ea5f8904-052c-488a-acb5-a587ce7928d6";

// Funciones fetch a la Api de Mercado Pago
const mercadoPago = {

    getIdentificationTypes: async function () {

        return fetch('https://api.mercadopago.com/v1/identification_types?public_key=' + public_key, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        }).then((response) => {
            return response.json();
        });


    },

    getPromoBancarias: async function () {
        var hitUrl = 'https://api.mercadopago.com/v1/payment_methods/deals?public_key=' + public_key;
        //console.log('hit url ', hitUrl);
        return fetch(hitUrl, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        }).then((response) => {
            return response.json();
        });

        /* **** AXIOS ***

        var response = await axios.get(hitUrl, {}, { timeout: 10 })
            .then((responseJson) => responseJson.data)
            .catch((error) => {
                //console.log('url', url_token, 'params', params);
                console.error('get MERCADOPAGO helper error ' + error);
            });
        return response;

        */
    },

    //bin son los primos 6 numeros de la tarjeta
    //amount el el total de la compra
    //Esto trae las cuotas permitidas de la tarjeta con mp
    getPaymentMethodsInstallments: async function (bin, amount) {
        let hitUrl = 'https://api.mercadopago.com/v1/payment_methods/installments?public_key=' + public_key + '&bin=' + bin + '&amount=' + amount;
        //console.log('hit url ', hitUrl);
        return fetch(hitUrl, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        }).then((response) => {
            return response.json();
        });

    },

    postCardToken: async function (card) {
        //console.log('card token to post', card);
        return fetch('https://api.mercadopago.com/v1/card_tokens?public_key=' + public_key, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(card)
        }).catch(err => console.log('error ', err))
            .then((response) => { return response.json(); });

    }

}

export default mercadoPago;
export { country };