import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './Components/Styling/Gotham-Medium-Regular.otf';
import './Components/Styling/Styles.css';


if (process.env.REACT_APP_ENVIROMENT === "PROD") {
    console.log = () => { };
}

ReactDOM.render(<App />, document.getElementById('root'));
