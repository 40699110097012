const currencyCode = 'ARS';


export default class AnalitycEvents {

    gtagProductoVisto = (variationSelected, product) => {
        var item_selected = this.retornaGtagItemSelected(variationSelected, product);

        //console.log('gtag view_item', item_selected);
        window.gtag('event', 'view_item', {
            "items": [item_selected],
            'ecomm_prodid': item_selected.id,
            'ecomm_pagetype': 'product',
            'ecomm_totalvalue': item_selected.prv_precio_real
        });


        /*let paramsRetargeting = {
            'ecomm_prodid': item_selected.id,
            'ecomm_pagetype': 'product',
            'ecomm_totalvalue': item_selected.prv_precio_real
        };*/
        //console.log('rtag page_view', paramsRetargeting);
        //window.gtag('event', 'product', paramsRetargeting);

    }

    gtagViewList = (productsSearch) => {
        var items = [];
        productsSearch && productsSearch.map((product, i) => {
            product.array_variaciones && product.array_variaciones.map((eachPrv, iPrv) => {
                items.push(
                    {
                        "id": eachPrv.prv_id,
                        "name": eachPrv.pro_nombre + ' ' + eachPrv.var_cantidad + ' ' + eachPrv.ume_resumido,
                        "list_name": "Lista de productos",
                        "brand": eachPrv.mar_nombre,
                        "quantity": 1,
                        "price": eachPrv.prv_precio_real
                    }
                );
                return items
            })
            return items
        });

        let params = {
            "items": items,
            'ecomm_prodid': items.map(obj => obj.id),
            'ecomm_pagetype': 'searchresults'
        };
        //console.log('gtag view_item_list', params);
        window.gtag('event', 'view_item_list', params);


        /*let paramsRegargeting = {
            'ecomm_prodid': items.map(obj => obj.id),
            'ecomm_pagetype': 'searchresults'
        };*/
        //console.log('search results view_item_list', paramsRegargeting);
        //window.gtag('event', 'searchresults', paramsRegargeting);
    }

    retornaGtagItemSelected = (variationSelected, product) => {
        var item_selected = {
            "id": variationSelected.prv_id,
            "name": product.pro_full_name,
            "list_name": "Producto",
            "brand": product.mar_name,
            'category': product.cat_name ? product.cat_name : null,
            "list_position": 1,
            "quantity": 1,
            "price": variationSelected.prv_price_real
        };
        return item_selected;
    }

    gTagAddressOpen = (params) => {
        //console.log('gtag address_rendered', params);
        window.gtag('event', 'address_rendered', params);
    }

    gTagConversionEvent = (order) => {
        //se manda por gtag Purchase
        /*window.gtag('event', 'conversion', 
        { 'send_to': 'AW-564790949/eva6CLeJxuEBEKWNqI0C', 'value': 1.0, 'currency': 'MXN', 'transaction_id': '' }
        ); */
    }

    gTagAddressSuccessful = () => {
        //console.log('gtag address_completed');
        window.gtag('event', 'address_completed');
    }

    gtagRemoveToCart(producto) {

        let item = {
            "id": producto.prv_id,
            "name": producto.pro_full_name,
            "list_name": "Carrito",
            "brand": producto.marca ? producto.marca : null,
            "quantity": producto.cantOfThisProduct,
            "price": producto.prv_price_real
        };
        //console.log('gtag remove_from_cart', item);
        window.gtag('event', 'remove_from_cart', {
            "items": [item]
        });
    }

    gtagAddToCart(producto) {
        let item = {
            "id": producto.prv_id,
            "name": producto.pro_full_name,
            "list_name": "Carrito",
            "brand": producto.marca ? producto.marca : null,
            "quantity": producto.cantOfThisProduct,
            "price": producto.prv_price_real
        };
        //console.log('gtag add_to_cart', item);
        window.gtag('event', 'add_to_cart', {
            "items": [
                item
            ]
        });

    }

    gtagGetItemsCarrito = () => {
        var productos_local = JSON.parse(localStorage.getItem("oliver_cart"));
        var items = []
        productos_local && productos_local.products.map((index, value) => {
            if (index && index.prv_id) {
                items.push(
                    {
                        "id": index.prv_id,
                        "name": index.pro_full_name,
                        "list_name": "Carrito",
                        "brand": index.marca,
                        "quantity": index.cantOfThisProduct,
                        "price": index.prv_price_real
                    }
                );
            }
            return items
            //console.log("items", items)
        });
        return items
    }

    gtagBeginCheckout() {
        var items = this.gtagGetItemsCarrito();
        //console.log('gtag begin_checkout', items);
        window.gtag('event', 'begin_checkout', {
            "items": items
        });
    }

    gtagProgressCheckout() {
        var items = this.gtagGetItemsCarrito();
        //console.log('gtag checkout_progress', items);
        window.gtag('event', 'checkout_progress', {
            "items": items
        });
    }

    gtagCheckoutOption(step, option) {
        //var items = this.gtagGetItemsCarrito();

        //console.log('gtag set_checkout_option', step, option);
        window.gtag('event', 'set_checkout_option', {
            "checkout_step": step,
            "checkout_option": option
        });
    }
    /**Se usa en ASK FOR DURATION EN WEB MP */
    gtagPurchaseCheckout(response) {
        var compra_id_sent = response.compra;
        var items = this.gtagGetItemsCarrito();
        var params = { "transaction_id": compra_id_sent, "value": response.compra_costo_total, "currency": currencyCode, "shipping": response.compra_costo_envio, "items": items }
        //console.log('gtag purchase', params);
        window.gtag('event', 'purchase', params);
    }

    gtagRefundPurchase(response) {
        var com_id = response.compra
        var com_costo_total = response.compra_costo_total
        var com_costo_envio = response.compra_costo_envio
        var items_compra = response.products;
        var items = [];
        items_compra.map((index, value) => {
            items.push({ "id": value.prv_id, "name": value.prv_producto_nombre, "list_name": "Refund", "brand": value.marca, "quantity": value.cor_cantidad, "price": value.cor_precio });
            return items
        });
        //console.log('gtag refund #', com_id);
        window.gtag('event', 'refund', { "transaction_id": com_id, "value": com_costo_total, "currency": currencyCode, "shipping": com_costo_envio, "items": items });

    }


    /** FACEBOOK ANALYTICS */

    FBOliverPlusPlanViews = () => {
        window.fbq('track', 'OliverPlusViewPlans');
    }

    detailPageFBView = (prod) => {
        let params = {
            content_ids: prod.prv_id,
            content_type: 'product',
            value: prod.prv_price_real,
            currency: currencyCode
        };
        //console.log('fb view content', params);
        window.fbq('track', 'ViewContent', params);
    }

    initiateCheckoutFBView = () => {
        var ids = [];
        var items = JSON.parse(localStorage.getItem("oliver_cart"));
        var itemsSend = [];
        var total = 0;
        items.products.map((obj, i) => {
            if (obj && obj.prv_id) {
                itemsSend.push({ "id": obj.prv_id, "quantity": obj.cantOfThisProduct, "item_price": obj.prv_price_unit });
                ids.push(obj.prv_id);
                total = total + (parseFloat(obj.prv_price_unit) * obj.cantOfThisProduct);
            }
            return total
        });
        let params = {
            content_ids: ids,
            contents: itemsSend,
            content_type: 'product',
            content_category: 'Carrito',
            num_items: itemsSend.length,
            value: total,
            currency: currencyCode
        };
        //console.log('initiate checkout', params);
        window.fbq('track', 'InitiateCheckout', params);
    }

    FBSuscription(response){
        window.fbq('track', 'Subscribe', {
            subscription_id: response.sus_id,
            value: response.total,
            currency: currencyCode,
        });
    }
 

    FBPurchase(response) {
        //console.log('response', response);
        var items_compra = response.products;
        var items = [];
        items_compra.map((obj, value) => {
            if (obj && obj.prv_id) {
                items.push({ "id": obj.prv_id, "quantity": obj.cor_cantidad, "item_price": obj.cor_precio });
            }
            return items
        });
        var purchase_fb = {
            contents: items,
            content_type: 'product',
            value: response.compra_costo_total,
            currency: currencyCode
        };
        //console.log('fb purchase', purchase_fb, response);
        window.fbq('track', 'Purchase', purchase_fb);
    }

    FBAddToCart = (producto) => {



        var items = [];
        items.push({ "id": producto.prv_id, "quantity": producto.cantOfThisProduct, "item_price": producto.prv_price_real });
        let params = {
            contents: items,
            content_name: producto.pro_full_name,
            content_type: 'product',
            value: producto.prv_price_real,
            currency: currencyCode
        };
        //console.log('fb add to cart', params);
        window.fbq('track', 'AddToCart', params);
    }

    FBSearch = (response) => {

        var items_compra = response.products.data;
        var items = [];
        items_compra && items_compra.map((product, i) => {
            product.array_variaciones.map((eachPrv, iPrv) => {
                items.push(
                    {
                        "id": eachPrv.prv_id,
                        "name": eachPrv.pro_nombre + ' ' + eachPrv.var_cantidad + ' ' + eachPrv.ume_resumido,
                        "quantity": 1,
                        "item_price": eachPrv.prv_precio_real
                    }
                );
                return items
            })
            return items
        });

        let params = {
            contents: items,
            content_name: 'Busqueda de productos',
            content_type: 'product'
        };
        //console.log('fb search', params);
        window.fbq('track', 'Search', params);
    }

}