import React from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { FbLogo } from '../Tools/OliverIcons';
import OliverHelper from '../../utils/Oliverhelper';
import AuthService from '../../utils/AuthService';
import { withRouter } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

const Auth = new AuthService();

class FacebookLoginButton extends React.Component {


  responseFacebook = (response) => {
    // Loading
    this.props.activeLoading && this.props.activeLoading()

    // console.log("RESPONSE FACEBOOK", response);

    if (response.id) {
      // Login
      var params = {
        email: response.email,
        fbid: response.id,
        nombre: response.first_name,
        apellido: response.last_name
      }

      this.login(params);
    }
  }

  login = (params) => {
    OliverHelper.ClientAPI.login(params).then((response) => {
      if (response && response.payload) {
        if (!response.payload.status) {
          alert(response.payload.message);
        } else {
          Auth.setTokenUser(response.payload.jwt);
          this.reloadPage();
        }
      }
    }).catch(error => {
      console.log('error en login', error);
    });
  }

  reloadPage = () => {
    if (window.location.pathname !== '/registro' && window.location.pathname !== '/registro/login' && window.location.pathname !== '/seguro/registro/login') {
      //window.location.reload();
      window.location.href = window.location.pathname;
    } else {
      this.props.history.push('/loading')
      return this.props.history.go();
    }
  }

  render() {

    return (
      <FacebookLogin
        appId="500506733635020"
        //autoLoad
        onClick={() => this.props.activeLoading && this.props.activeLoading()}
        callback={this.responseFacebook}
        isMobile={isMobile}
        redirectUri={this.props.redirectUri ? this.props.redirectUri : window.location.href}
        fields="id,email,name,last_name,first_name"
        render={renderProps => (
          <button className="RowCenter" onClick={renderProps.onClick}>
            <FbLogo />
            {(window.location.pathname !== '/carrito/para-pagar/' || !isMobile) &&
              <span >Continuar con Facebook</span>
            }
          </button>

        )}
      />
    );
  }
}

export default withRouter(FacebookLoginButton)