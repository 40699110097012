
const CART_NAME = 'oliver_cart';

const CartOliver = {


    addProductToCart: function (products, ind) {
        var cartStorage = localStorage.getItem(CART_NAME);
        var cartAux = { products: [], createdAt: new Date() };
        if (cartStorage) {
            cartAux = JSON.parse(cartStorage);
            var index = this.findProductIndexOfCart(products.prv_id, cartAux)
            //no existe el producto en el carrito
            if (index === null) {
                cartAux.products.push(products);
            } else {//encontramos el producto
                if (cartAux.products[index].cantOfThisProduct >= 1 && ind) {
                    cartAux.products[index].cantOfThisProduct = cartAux.products[index].cantOfThisProduct + 1;
                } else {
                    cartAux.products[index].cantOfThisProduct = products.cantOfThisProduct;
                }
            }
        } else {
            cartAux.products.push(products);
        }
        cartAux.modifiedAt = new Date();
        //luego de procesar el carrito, lo guardamos en memoria 
        this.saveCartToStorage(cartAux);
    },

    saveCartToStorage: function (cart) {
        cart = this.cartCalculateTotal(cart);
        localStorage.setItem(CART_NAME, JSON.stringify(cart));
    },

    clearAllCart: function () {
        localStorage.removeItem(CART_NAME);
    },
    findProductIndexOfCart: function (prvIdToSearch, cart) {
        var indice = null;
        cart.products.map((eachProduct, index) => {
            if (eachProduct.prv_id === prvIdToSearch) {
                indice = index;
            }
            return indice
        })
        return indice;
    },



    getItems: function () {
        const response = JSON.parse(localStorage.getItem(CART_NAME))
        return response
    },





    DeleteProdCart: function (products) {
        const cart = JSON.parse(localStorage.getItem(CART_NAME))
        const index = this.findProductIndexOfCart(products.prv_id, cart)
        var cartAux = cart;
        cartAux.products.splice(index, 1);
        //console.log("resta", cartAux.products)
        this.saveCartToStorage(cartAux);
    },

    cartCalculateTotal: function (cart) {
        var totalPrice = 0, totalCount = 0;
        cart.products.map((eachProduct) => {
            totalPrice = totalPrice + eachProduct.prv_price_real * eachProduct.cantOfThisProduct;
            totalCount = totalCount + eachProduct.cantOfThisProduct;
            return (totalCount, totalPrice);
        })
        cart.totalAmountProd = totalPrice
        cart.cantTotalProd = totalCount
        cart.shippingCost = 0
        return cart;

    }

}

export default CartOliver;