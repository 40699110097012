import React, { Suspense } from "react";


import NavbarGoBack from "../Navbar/NavbarGoBack";
import Layout from "../Layout/Layout";
import LayoutSeguro from "../Layout/LayoutSeguro";
import Home from "../Home/Home";
import NewAddress from "../Views/NewAddress";
import CartMobilleView from "../Views/CartMobilleView";
import Terminos, { Devoluciones, Envios, Privacidad } from "../Views/Terminos";

import PreguntasFrecuentes from '../Views/PreguntasFrecuentes'
import LoadingOmar from "../Tools/LoadingOmar";
import SearchView from "../Views/SearchView";

import { AnimacionSeguroDeclined, AnimacionSeguroAprobed } from '../Seguro/AnimacionSeguro';


//Lazy Loads
const IndividualProduct = React.lazy(() => import('../Views/IndividualProduct'));
const PurchaseOption = React.lazy(() => import('../Views/PurchaseOption'));
const PaymentMethod = React.lazy(() => import('../Views/PaymentMethod'));
const LayoutSimple = React.lazy(() => import('../Layout/LayoutSimple'));
const AltaMascotas = React.lazy(() => import('../Views/AltaMascotas'));
const PurchaseMade = React.lazy(() => import('../Views/PurchaseMade'));
const UserAccount = React.lazy(() => import('../Views/UserAccount'));
const ProductList = React.lazy(() => import('../Views/ProductList'));
const AddressList = React.lazy(() => import('../Views/AddressList'));
//const NewAddress = React.lazy(() => import('../Views/NewAddress'));
const SingUp = React.lazy(() => import('../Login/SingUp'));
const NewPet = React.lazy(() => import('../Views/NewPet'));
const PetProfile = React.lazy(() => import('../Views/PetProfile'));
//const CampoDePruebas = React.lazy(() => import('../Views/CampoDePruebas'));
const VetsList = React.lazy(() => import('../Views/VetsList'));
const SeguroPage = React.lazy(() => import('../Views/SeguroPage'));
const Loading = React.lazy(() => import('../Tools/Loading'));
const MobileCreditCard = React.lazy(() => import('../Tools/MobileCreditCard'));
const MobileBrandList = React.lazy(() => import('../Mobile/MobileBrandList'));
const Payments = React.lazy(() => import('../Views/Payments'));
const Seguro = React.lazy(() => import('../Views/Seguro'));


const URL_NAMING = {
  ENVIO: 'envio'
};

export default {

  publicRoutes: [
    /*{
      path: "/",
      exact: true,
      nav: true,
      label: 'Inicio',
      main: () => <Layout children={Home} case={'home'} showFooter={true} />
    },*/
    {
      path: "/",
      exact: true,
      nav: false,
      label: 'Inicio',
      main: () => <Layout children={Seguro} noNavbar={true} case={'Buscador'} showFooter={false} />
    },
    {
      path: "/loading",
      exact: true,
      nav: false,
      main: () => <Layout children={Loading} noNavbar={true} case={'home'} />
    },
    {
      path: "/card-payment",
      exact: true,
      nav: false,
      main: () => <Layout children={MobileCreditCard} case={'MobileCreditCard'} showFooter={false} />
    },
    {
      path: "/veterinarias",
      exact: true,
      nav: false,
      label: 'Inicio',
      main: () => <Layout children={VetsList} case={'VetsList'} showFooter={true} />
    },
    {
      path: "/seguro-mascota",
      exact: true,
      nav: false,
      label: 'Inicio',
      main: () => <Layout children={SeguroPage} case={'SeguroPage'} showFooter={true} />
    },
    {
      path: "/nueva-direccion",
      nav: false,
      exact: true,
      label: 'Alta dirección',
      main: () => <Layout children={NewAddress} case={'NuevaDireccion'} showFooter={true} />
    },
    {
      path: "/brand-list",
      nav: false,
      exact: true,
      label: 'Lista de Marcas',
      main: () => <Layout children={MobileBrandList} case={'BrandList'} showFooter={true} />
    },
    {
      path: "/payments",
      nav: false,
      exact: true,
      label: 'Formas de Pago',
      main: () => <Layout children={Payments} case={'Payments'} showFooter={false} />
    },
    {
      path: "/compra-finalizada",
      nav: false,
      exact: true,
      label: 'Compra Finalizada',
      main: () => <Layout children={PurchaseMade} case={'PurchaseMade'} showFooter={true} />
    },
    {
      path: "/productos/:categoria/:brand/:name/:id/:prv_id",
      nav: false,
      exact: true,
      label: 'Producto Individual',
      main: () => <Layout children={IndividualProduct} case={'IndividualProduct'} showFooter={true} />
    },
    {
      path: "/productos/:categoria/:brand/:name/:id",
      nav: false,
      exact: true,
      label: 'Producto Individual',
      main: () => <Layout children={IndividualProduct} case={'IndividualProduct'} showFooter={true} />
    },
    {
      path: `/${URL_NAMING.ENVIO}`,
      nav: false,
      exact: true,
      label: 'Purchase Option',
      main: () => <Layout children={PurchaseOption} case={'carrito'} showFooter={true} />
    },
    {
      path: "/carrito/mobile/",
      nav: false,
      exact: true,
      //      label: 'Purchase Option',
      main: () => <Layout children={CartMobilleView} case={'CartMobileView'} showFooter={true} />
    },
    {
      path: "/productos",
      exact: true,
      nav: false,
      label: 'Productos',
      main: () => <Layout children={ProductList} case={'ProductList'} showFooter={true} />
    },
    {
      path: "/productos/:categoria/:brand/ver-todo",
      exact: true,
      nav: false,
      label: 'Productos',
      main: () => <Layout children={ProductList} case={'home'} showFooter={true} />
    },
    {
      path: "/productos/([A-Za-z]+)",//por acá viene la magia de la expresión regular. 
      exact: true,
      nav: false,
      label: 'Productos',
      main: () => <Layout children={ProductList} case={'home'} showFooter={true} />
    },
    {
      path: "/carrito/para-pagar/",
      nav: false,
      exact: true,
      label: 'Purchase Option',
      main: () => <Layout children={PaymentMethod} case={'PaymentMethod'} />
    },
    {
      path: "/registro",
      exact: true,
      nav: true,
      label: 'Registrate',
      main: () => <Layout children={SingUp} case={'SingUp'} showFooter={true} />
    },
    {
      path: "/registro/login",
      exact: true,
      nav: true,
      label: 'Ya tengo cuenta',
      main: () => <Layout children={SingUp} case={'MobileCreditCard'} showFooter={false} />
    },/*
    {
      path: "/pruebas",
      exact: true,
      nav: false,
      label: 'Omar Lab',
      main: () => <Layout children={CampoDePruebas} />
    },*/
    {
      path: "/vendedores",
      exact: true,
      nav: false,
      label: 'vendedores y preguntas frecuentes',
      main: () => <Layout children={PreguntasFrecuentes} case={'PreguntasFrecuentes'} showFooter={true} />
    },
    {
      path: "/terminos-condiciones",
      exact: true,
      nav: false,
      label: 'terminos-condiciones',
      main: () => <Layout children={Terminos} case={'Terminos'} showFooter={true} />
    },
    {
      path: "/politicas-privacidad",
      exact: true,
      nav: false,
      label: 'politicas-privacidad',
      main: () => <Layout children={Privacidad} case={'Privacidad'} showFooter={true} />
    },
    {
      path: "/politicas-envios",
      exact: true,
      nav: false,
      label: 'politicas-envios',
      main: () => <Layout children={Envios} case={'Envios'} showFooter={true} />
    },
    {
      path: "/politicas-devoluciones",
      exact: true,
      nav: false,
      label: 'politicas-devoluciones',
      main: () => <Layout children={Devoluciones} case={'Devoluciones'} showFooter={true} />
    },
    {
      path: "/Buscador",
      exact: true,
      nav: false,
      label: 'Buscador',
      main: () => <Layout children={SearchView} case={'Buscador'} showFooter={false} />
    },
    // --- SEGURO
    {
      path: "/Seguro",
      exact: true,
      nav: false,
      label: 'Buscador',
      main: () => <Layout children={Seguro} noNavbar={true} case={'Buscador'} showFooter={false} />
    },
    {
      path: "/seguro/registro/login",
      exact: true,
      nav: true,
      label: 'Ya tengo cuenta',
      main: () => <LayoutSeguro children={SingUp} case={'MobileCreditCard'} showFooter={false} />
    },
    {
      path: "/seguro-card-payment",
      exact: true,
      nav: false,
      main: () => <LayoutSeguro children={MobileCreditCard} case={'MobileCreditCard'} showFooter={false} />
    },
    {
      path: "/seguro-nueva-direccion",
      nav: false,
      exact: true,
      label: 'Alta dirección',
      main: () => <LayoutSeguro children={NewAddress} case={'NuevaDireccion'} showFooter={false} />
    },
    // ---
    {
      path: "/AnimacionSeguro",
      exact: true,
      nav: false,
      label: 'Buscador',
      main: () => <Layout children={AnimacionSeguroDeclined} case={'Buscador'} showFooter={false} />
    },

    {
      path: "/AnimacionSeguroAprobed",
      exact: true,
      nav: false,
      label: 'Buscador',
      main: () => <Layout children={AnimacionSeguroAprobed} case={'Buscador'} showFooter={false} />
    },

  ],



  routesLogged: [
    {
      path: "/mis-direcciones",
      exact: true,
      nav: false,
      label: 'Direcciones',
      main: () => <Layout children={AddressList} case={'AddressList'} showFooter={true} />
    },
    {
      path: "/mascotas",
      exact: true,
      nav: false,
      label: 'Mascotas',
      main: () => <Layout children={AltaMascotas} case={'AltaMascotas'} showFooter={true} />
    },
    {
      path: "/mi-cuenta",
      exact: true,
      nav: true,
      label: 'Mi Cuenta',
      main: () => <Layout children={UserAccount} case={'UserAccount'} showFooter={true} />
    },
    {
      path: "/alta-mascota",
      exact: true,
      nav: false,
      label: 'Alta mascota',
      //main: () => <LayoutSimple navbar={NavbarGoBack} children={<section className='Canvas'><NewPet /></section>} />
      main: () => <Suspense fallback={<LoadingOmar />}><LayoutSimple navbar={NavbarGoBack} children={NewPet} /></Suspense>
    },
    {
      path: "/perfil-de/:nombreDeLaMascota",
      exact: true,
      nav: false,
      label: 'Datos de tu Mascota',
      main: () => <Layout children={PetProfile} case={'perfilMascota'} showFooter={true} />
    },
    {
      path: "/perfil-de/:nombreDeLaMascota/:view",
      exact: true,
      nav: false,
      label: 'Datos de tu Mascota',
      main: () => <Layout children={PetProfile} case={'perfilMascota'} showFooter={true} />
    },
  ],

};

export { URL_NAMING };