import React, { Suspense } from 'react';
import { withRouter, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faShoppingCart, faArrowLeft, faHeadset, faHome } from '@fortawesome/free-solid-svg-icons';
import Cart from './Cart';
import { OliverLogo } from '../Tools/OliverIcons';
import LoadingNav from "../Loading/LoadingNav";

const Sidebar = React.lazy(async () => await import('./Sidebar'))
const UserAddress = React.lazy(async () => await import('../Address/UserAddress'))

// Zoho
const zoho = window.$zoho;

class MobileNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      estaLogueado: true,
      showSearchBox: false,
      showMenuBox: false,
    };
  }

  logIn = () => this.setState({ estaLogueado: true })
  logOut = () => this.setState({ estaLogueado: true })

  showSearchBox = () => {
    return (
      <nav className='MobileNavbar RowCenter'>
        <button className='BtnMobileNavbar' onClick={() => { this.setState({ showSearchBox: false }) }}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <input alt='¿que necesitas?' autoFocus type='text' />
        <button className='BtnMobileNavbar'>
          <FontAwesomeIcon icon={faShoppingCart} />
        </button>
      </nav>
    )
  };

  toggleModal = () => this.setState({ showMenuBox: !this.state.showMenuBox })

  reloadPage = () => {
    if (window.location.pathname !== '/' && window.location.pathname !== '/carrito/para-pagar/') {
      this.props.history.push('/')
    }
  }

  goHome = () => {
    this.props.history.push('/')
  }

  goToLogged = () => {
    this.props.history.push('/loading')
    return this.props.history.go()
  }

  render() {
    return (
      <nav className='MobileNavbar RowCenter'>
        <Suspense fallback={<LoadingNav />}>
          <LeftComponent
            param={this.props.case}
            logOut={this.logOut}
            toggleDrawer={this.props.toggleDrawer}
            showSide={this.props.showSide}
            searchFocus={this.props.searchFocus}
            showDrawer={this.props.showDrawer}
            goHome={this.goHome}
            goToLogged={this.goToLogged}
          />

          <CenterComponent
            param={this.props.case}
            showAddress={this.props.showAddress}
            showDrawer={this.props.showDrawer}
            reloadPage={this.reloadPage}
          />


          <RightComponent
            param={this.props.case}
            showCart={this.props.showCart}
            showDrawer={this.props.showDrawer}
          />
        </Suspense>
      </nav>
    )
  }
}


export default withRouter(MobileNavbar);


const BackButton = ({ goHome }) => {
  let history = useHistory()

  return (
    <button className='NavbarBackIcon' onClick={() => goHome ? goHome() : history.goBack()}>
      <FontAwesomeIcon icon={faArrowLeft} />
    </button>
  )
}

function ChatButton({ children }) {
  return (
    <button className='NavbarBackIcon' onClick={() => zoho.salesiq.chat.start()}>
      <FontAwesomeIcon icon={faHeadset} />
    </button>
  )
}


const LeftComponent = ({ goToLogged, param, logOut, toggleDrawer, showSide, showDrawer, goHome, searchFocus }) => {
  console.log("searchFocus", searchFocus)
  switch (param) {
    case 'home': return <Suspense fallback={<div />}><Sidebar logOut={logOut} showMobileSearchBox={true} searchFocus={searchFocus} toggleDrawer={toggleDrawer} showElement={showSide} showDrawer={showDrawer} /></Suspense>

    case 'ProductList': return <BackButton />

    case 'carrito': return <BackButton />

    case 'PaymentMethod': return <BackButton />

    case 'SingUp': return <BackButton goHome={goHome} />

    case 'NuevaDireccion': return <BackButton />

    case 'SeguroPage': return <BackButton />

    case 'IndividualProduct': return <BackButton />

    case 'VetsList': return <BackButton />

    case 'perfilMascota': return <BackButton />

    case 'BrandList': return <BackButton />

    case 'Payments': return <BackButton />

    case 'UserAccount': return <BackButton goHome={goHome} />

    case 'MobileCreditCard': return <BackButton />

    case 'CartMobileView': return <BackButton />

    case 'PreguntasFrecuentes': return <BackButton />

    case 'Terminos': return <BackButton />

    case 'Envios': return <BackButton />

    case 'Devoluciones': return <BackButton />

    case 'Buscador': return <BackButton />
    
    case 'PurchaseMade':
      return (
        <button className='NavbarBackIcon' onClick={() => goToLogged()}>
          <FontAwesomeIcon icon={faHome} />
        </button>
      )

    default: return <div style={{ width: '32px', marginRight: '8px' }} />;
  }
}

const RightComponent = ({ param, showCart, showDrawer }) => {

  switch (param) {
    case 'home': return <Cart showElement={showCart} showDrawer={showDrawer} />

    case 'ProductList': return <Cart showElement={showCart} showDrawer={showDrawer} />

    case 'IndividualProduct': return <Cart showElement={showCart} showDrawer={showDrawer} />

    case 'carrito': return <ChatButton />

    case 'PaymentMethod': return <ChatButton />

    case 'PurchaseMade': return <ChatButton />

    case 'BrandList': return <Cart showElement={showCart} showDrawer={showDrawer} />

    default: return <div style={{ width: '32px', marginRight: '8px' }} />;
  }
}

const CenterComponent = ({ param, showAddress, showDrawer, reloadPage }) => {


  switch (param) {
    case 'home': return <UserAddress showElement={showAddress} showDrawer={showDrawer} />

    case 'ProductList': return <UserAddress showElement={showAddress} showDrawer={showDrawer} />

    case 'IndividualProduct': return <UserAddress showElement={showAddress} showDrawer={showDrawer} />

    case 'carrito': return <UserAddress showElement={showAddress} showDrawer={showDrawer} />

    default: return <OliverLogo reloadPage={reloadPage} />
  }
}


