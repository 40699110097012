import React, { Suspense } from "react";
import HomeDrawerGeneric from "../Tools/HomeDrawerGeneric";
import { isMobile } from "react-device-detect";
import Footer from "./Footer";
import LoadingOmar from "../Tools/LoadingOmar";
import ModalGeneric from '../Tools/ModalGeneric';

import { withRouter } from "react-router-dom";
import { NavBarSeguro } from '../Views/Seguro'

class LayoutSeguro extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            width: window.innerWidth,
            showDrawer: false,
            contentDrawer: '',
            showAddress: false,
            showSide: false,
            showCart: false,
            showLoginForm: false,
            // Modal
            showModalGeneric: false,
            modalTitle: '',
            modalBody: '',
        };
    }

    toggleDrawer = (contentDrawer, data, show) => {
        this.setState({ contentDrawer: contentDrawer, showDrawer: show ? show : !this.state.showDrawer, data: data })
    }

    showDrawer = (value, focus) => {
        console.log("Quiere show drawer", focus)
        if (value === 'direc') {
            var resultAddres = !this.state.showAddress
        } else if (value === 'side') {
            var resultside = !this.state.showSide
        } else if (value === 'carrito') {
            var resultcarrito = !this.state.showCart
        } else if (value === 'search') {
            resultcarrito = false
            resultAddres = false
        }

        this.setState({ showAddress: resultAddres, showSide: resultside, showCart: resultcarrito, searchFocus: focus })
    }

    toggleModal = () => this.setState({ showLoginForm: !this.state.showLoginForm })

    showModalGeneric = (title, body) => {
        this.setState({
            showModalGeneric: true,
            modalTitle: title,
            modalBody: body
        })
    }

    render() {
        //console.log('re render Home.js => el ancho de la pantalla es de: ', this.state.width);

        // Props
        const Children = this.props.children;

        let context = isMobile ? 'Mobile layoutSeguro' : 'Desktop layoutSeguro';

        return (
            <main className={context}>
                {!this.props.noNavbar &&
                    <NavBarSeguro back={() => this.props.history.goBack()} />
                }

                <Suspense fallback={<LoadingOmar />}>
                    <Children toggleDrawer={this.toggleDrawer} showDrawer={this.showDrawer} toggleModal={this.toggleModal} showModalGeneric={this.showModalGeneric} />
                </Suspense>

                {
                    isMobile ?
                        null
                        :
                        <HomeDrawerGeneric
                            content={this.state.contentDrawer}
                            data={this.state.data}
                            toggleDrawer={this.toggleDrawer}
                            showTheDrawerGeneric={this.state.showDrawer}
                            showModalGeneric={this.showModalGeneric}
                            toggleModal={this.toggleModal}
                        />
                }

                {this.props.showFooter && <Footer />}
                <ModalGeneric
                    closeModal={() => this.setState({ showModalGeneric: false })}
                    showModal={this.state.showModalGeneric}
                    title={this.state.modalTitle}
                    body={this.state.modalBody}
                />
            </main>
        );
    }
}

export default withRouter(LayoutSeguro);