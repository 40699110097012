import React, { Component } from 'react'

export default class TheyTrustUs extends Component {
  render() {
    return (
      <section className='TheyTrustUs'>
        <h4>Ya confian en nosotros</h4>
        <ul>
          <li>
            <img src="https://oliver.pet/public/web/images/forbes.png" alt="" />
          </li>
          <li>
            <img src="https://oliver.pet/public/web/images/endeavor.png" alt="" />
          </li>
          <li>
            <img src="https://oliver.pet/public/web/images/santander.png" alt="" />
          </li>
          <li>
            <img src="https://oliver.pet/public/web/images/google.png" alt="" />
          </li>
          <li>
            <img src="https://oliver.pet/public/web/images/facebook.png" alt="" />
          </li>
          <li>
            <img src="https://oliver.pet/public/web/images/lanacion.png" alt="" />
          </li>
        </ul>
        {/**<div className="RowCenter">
          <img src={require('../../IMG/logo-apple-store.png')} alt=""/>
          <img src={require('../../IMG/logo-google-store.png')} alt=""/>
        </div> */}
      </section>
    )
  }
}
