import React, { useState, useEffect, Suspense } from 'react';
import { isMobile } from 'react-device-detect';
import { FaCheckCircle, FaAngleRight, FaDog, FaCat, FaAngleDown } from "react-icons/fa";
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import OliverHelper from '../../utils/Oliverhelper';
import AuthService from '../../utils/AuthService';
import mercadoPago from '../../utils/mercadoPago';
import { country } from '../../utils/mercadoPago';
import * as firebase from '../../utils/firebase';
import NumberFormatJs from '../../functions/numberFormat';
import translate from '../../languages';
import './../Seguro/seguro.css';
import Select from 'react-select';
import LoadingOmar from "../Tools/LoadingOmar";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MisPichoslogo from '../../../src/IMG/seguro/logo-mispichos-blanco.png';
import LoadingNav from "../Loading/LoadingNav";
import { faAngleDown, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import { AnimacionSeguroDeclined, AnimacionSeguroAprobed } from '../Seguro/AnimacionSeguro';
import Icons from '../../utils/Icons';

import imageLogo from '../../IMG/seguro/oliver_plus_fondo_azul.jpg';
import playstore from '../../IMG/logo-google-store.png';
import applestore from '../../IMG/logo-apple-store.png';
import { FaHeart, FaShieldAlt } from "react-icons/fa";
import AnalitycEvents from '../../utils/AnalyticsTag';

const Analitycs = new AnalitycEvents()



const Auth = new AuthService();

function Seguro({ toggleDrawer, toggleModal }) {

  // State
  const [loading_status, setLoadingStatus] = useState(true);
  const [pageStatus, set_pageStatus] = useState('onboarding');
  const [logged, setLogged] = useState(null);

  // COVERAGE
  const [coverage, setCoverage] = useState(null);
  const [responseCoverage, setResponseCoverage] = useState(null);

  // Data Suscripcion - coverage
  const [animal, setAnimal] = useState(null);
  const [plan_selected, setPlan] = useState(null);
  const [profile, setProfile] = useState(null);
  const [domicilio, setDomicilio] = useState(null);

  // Asociar pago
  const [animalEntity, setAnimalEntity] = useState(null);

  // Planes disponibles
  const [plans, setPlans] = useState([
    {
      id: 1,
      nombre: "Premium",
      precio: 759,
      precio_lista: 1050,
      terminos_condiciones: "https://mispichos.com/public/web/images/oliver_plus_terminos_premium.pdf",
      caracteristicas: [
        "Cirugías, estudios y análisis veterinarios hasta $30.000",
        "Gastos veterinarios por enfermedad y accidente hasta $30.000",
        "Vacunas hasta $2.200",
        "Consultas veterinarias online ilimitadas",
        "Responsabilidad Civil hasta $250.000",
        "Descuentos en alimento y accesorios hasta 10%",
      ]
    },
    {
      id: 2,
      nombre: "Base",
      precio: 259,
      precio_lista: 550,
      terminos_condiciones: "https://mispichos.com/public/web/images/oliver_plus_terminos_base.pdf",
      caracteristicas: [
        "Gastos veterinarios por accidente hasta $2.500",
        "Intervención quirúrgica por accidente hasta $2.500",
        "Vacunas hasta $1.100",
        "Consultas veterinarias online hasta 2",
        "Responsabilidad civil hasta $195.000",
      ]
    },
  ]);

  const context = isMobile ? 'Mobile' : 'Desktop';
  // Navigation
  const history = useHistory();
  const locationHistory = useLocation();


  // Use effect
  useEffect(() => {
    valdiateNavigate();
  }, []);

  const valdiateNavigate = async () => {
    let logged_aux = Auth.loggedIn();

    if (logged_aux) {
      setLogged(logged_aux)

      // Si viene por formulario nueva tarjeta
      if (locationHistory.state && locationHistory.state.step == 'asociar' && locationHistory.state.AnimalEntity) {
        setAnimalEntity(locationHistory.state.AnimalEntity);
        set_pageStatus("asociar");
      }

      // Si viene normal
      else {

        console.log("PASAMOS VALIDACION")

        let coverage = getCoverageStorage();

        // Validamos coverage - si viene por loggin para terminar proceso
        if (coverage && coverage.animal && coverage.profile && coverage.plan_selected) {

          // Validacion de pet list
          let response = await OliverHelper.ClientAPI.getAnimals();
          if (response.payload.data) {
            let aux_animals = response.payload.data;
            aux_animals.map((axu_ani, u) => {
              if (axu_ani.name == coverage.animal.name) {
                coverage.animal.id = axu_ani.id
              }
            })
          }

          setCoverage(coverage);
          setAnimal(coverage.animal);
          setProfile(coverage.profile);
          setPlan(coverage.plan_selected);
          set_pageStatus("crear");
        }

      }
    } else {
      // Validamos coverage - si viene por loggin paso atras
      let coverage = getCoverageStorage();
      if (coverage && coverage.animal && coverage.profile && coverage.plan_selected) {
        setCoverage(coverage);
        setAnimal(coverage.animal);
        setProfile(coverage.profile);
        setPlan(coverage.plan_selected);
        set_pageStatus("contratar")
      }
    }

    setLoadingStatus(false);
  }

  if (loading_status) {
    return (<LoadingOmar />)
  }

  if (pageStatus === 'onboarding') {
    return <Onboarding context={context} pageStatus={(status) => set_pageStatus(status)} />
  } else if (pageStatus === 'secondStep') {
    return <SecondStep context={context} pageStatus={(status) => set_pageStatus(status)} />
  } else if (pageStatus === 'petList') {
    return <PetList
      context={context}
      pageStatus={(status) => set_pageStatus(status)}
      setProfile={setProfile}
      setAnimal={setAnimal}
      logged={logged}
      setAnimalEntity={setAnimalEntity}
    />
  } else if (pageStatus === 'cotizar') {
    return <Cotizar
      context={context}
      pageStatus={(status) => set_pageStatus(status)}
      profile={profile}
      setProfile={setProfile}
      animal={animal}
      setAnimal={setAnimal}
    />
  } else if (pageStatus === 'contratar') {
    return <Contratar
      context={context}
      pageStatus={(status) => set_pageStatus(status)}
      plans={plans}
      plan_selected={plan_selected}
      setPlan={setPlan}
      profile={profile}
      animal={animal}
      logged={logged}
      setCoverage={setCoverage}
      toggleDrawer={toggleDrawer}
      toggleModal={toggleModal}
    />
  } else if (pageStatus === 'crear') {
    return <Crear
      context={context}
      pageStatus={(status) => set_pageStatus(status)}
      coverage={coverage}
      setCoverage={setCoverage}
      history={history}
      locationHistory={locationHistory}
      domicilio={domicilio}
      setDomicilio={setDomicilio}
      responseCoverage={responseCoverage}
      setResponseCoverage={setResponseCoverage}
      setAnimalEntity={setAnimalEntity}
    />
  } else if (pageStatus === 'asociar') {
    return <Asociar
      context={context}
      pageStatus={(status) => set_pageStatus(status)}
      history={history}
      locationHistory={locationHistory}
      responseCoverage={responseCoverage}
      setResponseCoverage={setResponseCoverage}
      AnimalEntity={animalEntity}
    />
  } else if (pageStatus === 'info') {
    return <Info
      context={context}
      pageStatus={(status) => set_pageStatus(status)}
      coverage={coverage}
      setCoverage={setCoverage}
      responseCoverage={responseCoverage}
      history={history}
      animal={animalEntity}
    />
  } else {
    return (<h1>Algo salio mal :(</h1>)
  }
}

function Onboarding({ pageStatus, context }) {
  const [preguntaSelected, setPreguntaSelected] = useState(null)
  // Use effect
  useEffect(() => {

    // Firebase Analytic
    firebase.sendToAnalyticsEvent('web_cobertura_landing')

  }, []);

  const commits = [
    {
      title: '¿Puedo elegir el veterinario que yo quiera?',
      data: 'Si, vos podés elegir el veterinario matriculado que quieras porque no trabajamos con una cartilla. Solo tenés que pedirle los comprobantes que te solicitamos para efectuar el reintegro.',
    },
    {
      title: '¿La cobertura está en todo el país?',
      data: 'Si, en toda Argentina desde La Quiaca hasta Ushuaia.',
    },
    {
      title: '¿Cómo pago la cobertura mensual?',
      data: 'El pago es por débito automático en tu tarjeta de crédito/débito. La tarjeta la cargás en el último paso de alta de la mascota. La cobertura es individual por mascota.',
    },
    {
      title: '¿Puedo darme de baja en cualquier momento?',
      data: 'Si aún no usaste ningún reintegro veterinario, de vacunas o responsabilidad civil, podés darte de baja en cualquier momento. Si hiciste uso de alguno de estos servicios el tiempo mínimo son 12 meses.',
    },
  ]

  if (isMobile) {
    return (
      <section className={context + ' Onboarding'}>
        <NavBarSeguro />
        <ol className='SeguroListaImgs'>
          <li>
            <img src={'https://s3.sa-east-1.amazonaws.com/mispichos.com.ar/user-interface-assets/Oliver-Plus-blocks/block-1.png'} alt='' />
          </li>
          <li>
            <img src={'https://s3.sa-east-1.amazonaws.com/mispichos.com.ar/user-interface-assets/Oliver-Plus-blocks/block-2Nueva.png'} alt='' />
          </li>
          <li>
            <img src={'https://s3.sa-east-1.amazonaws.com/mispichos.com.ar/user-interface-assets/Oliver-Plus-blocks/block-3.png'} alt='' />
          </li>
          <li>
            <img src={'https://s3.sa-east-1.amazonaws.com/mispichos.com.ar/user-interface-assets/Oliver-Plus-blocks/block-4.png'} alt='' />
          </li>
          <li>
            <img src={'https://s3.sa-east-1.amazonaws.com/mispichos.com.ar/user-interface-assets/Oliver-Plus-blocks/block-5.png'} alt='' />
          </li>
          <li>
            <div className="Card CardComentarios">
              <img src={'https://s3.sa-east-1.amazonaws.com/mispichos.com.ar/user-interface-assets/Oliver-Plus-blocks/preguntas-frecuentes.png'} alt='' />
              {commits.map((item, i) =>
                <div className="acordeon">
                  <div className="acordeonHead">
                    <h4>{item.title}</h4>
                    <FaAngleDown />
                  </div>
                  <div className="acordeonBody">
                    <p>{item.data}</p>
                  </div>
                </div>
              )}
            </div>
          </li>

          <li>
            <img src={'https://s3.sa-east-1.amazonaws.com/mispichos.com.ar/user-interface-assets/Oliver-Plus-blocks/block-6.png'} alt='' />
          </li>
          <li>
            <img src={'https://s3.sa-east-1.amazonaws.com/mispichos.com.ar/user-interface-assets/Oliver-Plus-blocks/block-7.png'} alt='' />
          </li>
          <li>
            <img src={'https://s3.sa-east-1.amazonaws.com/mispichos.com.ar/user-interface-assets/Oliver-Plus-blocks/block-8.png'} alt='' />
          </li>
          <li>
            <img src={'https://s3.sa-east-1.amazonaws.com/mispichos.com.ar/user-interface-assets/Oliver-Plus-blocks/block-9.png'} alt='' />
          </li>
        </ol>
        <div className="StickyBottomBox Botonera">
          <div className="StickyBottomBoxBtn" onClick={() => pageStatus('secondStep')}>
            <h4>Cotizar ahora</h4>
            <small>Sólo en 30 segundos</small>
          </div>
        </div>
      </section>
    )
  } else {
    return (
      <article className={context + ' OnboardingDesktop'}>
        <img src="https://s3.sa-east-1.amazonaws.com/mispichos.com.ar/user-interface-assets/Oliver-Plus-blocks/oliver_landing_page_desktopNueva.png" alt="landing_desktop" />

        <ol className='listPopUps'>
          {commits.map(i => <li onClick={() => setPreguntaSelected(i)} />)}
        </ol>
        <div className="Botonera">
          <div className="StickyBottomBoxBtn" onClick={() => pageStatus('secondStep')}>
            <h4>Cotizar ahora</h4>
            <small>Sólo en 30 segundos</small>
          </div>
        </div>
        {preguntaSelected &&
          <div className='modal-over' >
            <div className='modal-body'>
              <h4>{preguntaSelected.title}</h4>
              <p>{preguntaSelected.data}</p>
              <div className="RowCenter spaceBetween">
                <div></div>
                <button onClick={() => setPreguntaSelected(null)}>Listo</button>
              </div>
            </div>
          </div>
        }

      </article>
    )
  }
}

function SecondStep({ pageStatus, context }) {

  // Use effect
  useEffect(() => {

    // Firebase Analytic
    firebase.sendToAnalyticsEvent('web_cobertura_landing')

  }, []);


  return (
    <section className={context + ' Onboarding'}>
      <NavBarSeguro back={() => pageStatus('onboarding')} />
      <ol className='SeguroListaImgs'>
        <li>
          <img src={'https://s3.sa-east-1.amazonaws.com/mispichos.com.ar/user-interface-assets/Oliver-Plus-blocks/mail_OP_Planes_aNueva.png'} alt='' />
        </li>
        <li>
          <a className='Presseable' href="https://mispichos.com/public/web/images/oliver_plus_terminos_premium.pdf" target={'_blank'}></a>
          <a className='Presseable' href="https://mispichos.com/public/web/images/oliver_plus_terminos_base.pdf" target={'_blank'}></a>
        </li>
        <li>
          <img src={'https://s3.sa-east-1.amazonaws.com/mispichos.com.ar/user-interface-assets/Oliver-Plus-blocks/mail_OP_Planes_b.png'} alt='' />
        </li>
      </ol>
      <div className="StickyBottomBox Botonera">
        <div className="StickyBottomBoxBtn" onClick={() => pageStatus('petList')}>
          <h4>Cotizar ahora</h4>
          <small>Sólo en 30 segundos</small>
        </div>
      </div>
    </section>
  )
}

function PetList({ pageStatus, context, setProfile, setAnimal, logged, setAnimalEntity }) {

  // State
  const [loading, setLoading] = useState(true);
  const [animals, setAnimals] = useState([]);

  // Use effect
  useEffect(() => {

    // Firebase Analytic
    firebase.sendToAnalyticsEvent('web_cobertura_formulario_mascota')

    if (logged) {
      getData();
    }
    else {
      setLoading(false);
      pageStatus('cotizar')
    }
    // eslint-disable-next-line
  }, []);

  // Get Data logueado
  const getData = async () => {
    // animal
    let response = await OliverHelper.ClientAPI.getAnimals();
    // profile
    let UserData = await OliverHelper.ClientAPI.getProfileData()
    var params = {
      user_lastname: UserData.payload.lastname,
      email: UserData.payload.email,
      user_name: UserData.payload.name,
      password: "",
      rePassword: "",
      phone: UserData.payload.phone,
      cod_area: UserData.payload.phone_cod_area ? UserData.payload.phone_cod_area : "011"
    }

    setProfile(params);
    if (response.payload.data) {
      setAnimals(response.payload.data);
      setLoading(false);
    } else {
      pageStatus('cotizar')
      setLoading(false);
    }

  }

  // Select Animal
  const selectedAnimal = (animal) => {
    setAnimal(animal)
    pageStatus('cotizar')
  }

  // Navigate to animal
  const navigateByAnimal = (ani) => {
    if (!ani.suscription) {
      selectedAnimal(ani)
    } else {
      if (ani.suscription.fechaAltaPoliza) {
        //window.location.replace("https://mispichos.com")
        setAnimalEntity(ani);
        pageStatus('info')
      } else {
        setAnimalEntity(ani);
        pageStatus('asociar')
      }
    }
  }

  if (loading) {
    return (<LoadingOmar />)
  }

  return (
    <section className={context + ' petList'}>
      <NavBarSeguro back={() => pageStatus('secondStep')} />
      <div>
        <h4>Elegí uno de tus pichos</h4>
        <ol>
          {animals.map((ani, index) =>
            <li key={index} onClick={() => navigateByAnimal(ani)}>
              <article>
                <div className="RowCenter">
                  <img src={ani.photo ? ani.photo : "https://s3.sa-east-1.amazonaws.com/mispichos.com.ar/user-interface-assets/wo_elementos/oliver_mobile.jpg"} lazy alt="" />
                  <h6>{ani.name}</h6>
                  {ani.suscription ?
                    (
                      ani.suscription.fechaAltaPoliza ?
                        <span style={{ color: "#1fa9e7", fontWeight: 'bold', fontSize: '0.8rem' }}>(Con cobertura)</span>
                        :
                        <span style={{ color: "#ffb300", fontWeight: 'bold', fontSize: '0.8rem' }}>(Pendiente de Alta)</span>
                    )
                    :
                    <span className='sinc'>(Sin cobertura)</span>
                  }
                </div>
                <FaAngleRight />
              </article>
            </li>
          )}
        </ol>
      </div>
      <div className="StickyBottomBox Botonera">
        <button className="btn btn-acttive" onClick={() => pageStatus('cotizar')}>
          Cotizar nueva mascota
        </button>
      </div>
    </section >
  )
}

function Cotizar({ pageStatus, context, profile, setProfile, animal, setAnimal }) {

  // animal
  const [type, setType] = useState(null)
  const [name, set_name] = useState(null)
  const [date, set_date] = useState(null)
  const [breed, set_breed] = useState(null)
  const [sex, set_sex] = useState(null)

  // profile
  const [mail, set_mail] = useState(null)
  const [tel, set_tel] = useState(null)

  // state
  const [breed_selected, setBreedSelected] = useState(null)
  const [breeds_select, setBreedsSelect] = useState([])
  const [btn_title, setBtnTitle] = useState("Cotizar")

  // Use effect
  useEffect(() => {

    // Si selecciono Animal
    if (animal) {
      selectType(animal.animal)
      set_name(animal.name)
      set_date(animal.birth)
      if (animal.raza) selectBreed(animal.raza)
      set_sex(animal.sex)
    }

    if (profile) {
      set_mail(profile.email)
      set_tel(profile.phone)
    }
    // eslint-disable-next-line
  }, []);

  // Get Razas
  const getBreeds = async (typeSelected) => {

    let response = await OliverHelper.ClientAPI.getAnimalBreeds();

    let razas = response.payload.data
    let Aux = []
    let AuxSelect = []
    // Primero mestizo
    razas.map((eachBreed) => {
      if (eachBreed.especie === typeSelected) {
        if (eachBreed.name === "Mestizo") {
          Aux.push(eachBreed)
          AuxSelect.push({ value: eachBreed, label: "Mestizo/Sin raza", especie: eachBreed.especie })
        }
      }
      return AuxSelect;
    })
    // Luego demas
    razas.map((eachBreed) => {
      if (eachBreed.especie === typeSelected) {
        if (eachBreed.name === "Mestizo") return null;
        Aux.push(eachBreed)
        AuxSelect.push({ value: eachBreed, label: eachBreed.name, especie: eachBreed.especie })
      }
      return AuxSelect;
    })
    setBreedsSelect(AuxSelect);
  }

  // Tipo
  const selectType = async (typeSelected) => {
    setType(typeSelected)
    getBreeds(typeSelected)
  }

  // Raza
  const selectBreed = (breedSelected) => {
    set_breed(breedSelected.id)
    setBreedSelected(breedSelected)
  }

  // --- Cotizar

  // Cargar
  const loadNavigateToPlan = () => {
    if (!validateData()) return null;

    setBtnTitle("Cotizando el plan de " + name + "...");
    setTimeout(() => {
      navigateToPlan();
    }, 2000)
  }

  // Validar
  const validateData = () => {
    var resolve = true
    if (!type) {
      alert("Debes seleccionar perro o gato")
      resolve = false
    } else if (!name) {
      alert("Debes ingresar el nombre de tu mascota")
      resolve = false
    } else if (!date) {
      alert("Debes ingresar la fecha de nacimiento")
      resolve = false
    }
    else if (!OliverHelper.validateDaysGreaterThan(date, 90)) {
      alert("Su mascota debe tener más de 3 meses de edad para suscribirse");
      resolve = false
    }
    else if (!OliverHelper.validateDaysLowerThan(date, 3285)) {
      alert("Su mascota debe tener como máximo 9 años para suscribirse");
      resolve = false
    } else if (!breed) {
      alert("Debes seleccionar una raza")
      resolve = false
    } else if (!sex) {
      alert("Debes seleccionar el sexo de la mascota")
      resolve = false
    } else if (!mail) {
      alert("Debes ingresar un email")
      resolve = false
    } else if (!OliverHelper.validateEmail(mail)) {
      alert("Debes ingresar un email válido");
      resolve = false;
    } else if (!tel) {
      alert("Debes ingresar un teléfono")
      resolve = false
    }
    else if (!OliverHelper.validatePhoneNumber(tel)) {
      alert("Debes ingresar un teléfono válido")
      resolve = false
    }

    return resolve
  }

  // Cotizar
  const navigateToPlan = () => {

    // Formateamos Animal
    var aux_animal = {};
    if (animal) {
      aux_animal = animal;
    }

    aux_animal.animal = type
    aux_animal.name = name;
    aux_animal.birth = date;
    aux_animal.breed = breed;
    aux_animal.sex = sex;

    // Formateamos Profile
    var aux_profile = {};
    if (profile) {
      aux_profile = profile;
    }

    aux_profile.email = mail;
    aux_profile.phone = tel;

    setAnimal(aux_animal)
    setProfile(aux_profile)

    // Coverage - primeros datos
    let coverage = {
      animal: aux_animal,
      profile: aux_profile,
    }
    // Guardar en Firebase
    var folder_id = firebase.create('suscriptions', coverage);
    coverage.folder_id = folder_id;
    // Guardamos Data en Storage
    setCoverageStorage(coverage);

    pageStatus('contratar')

  }

  return (
    <section className={context + ' FormCotizar'}>
      <NavBarSeguro back={() => { pageStatus('secondStep'); setAnimal(null); }} />
      <div>
        <article>
          {/* tipo */}
          <div className="ColDuo">
            <div className={type === 'perro' ? "iconBoxAct" : "iconBox"} onClick={() => selectType('perro')}>
              <FaDog className='aux' />
            </div>
            <div className={type === 'gato' ? "iconBoxAct" : "iconBox"} onClick={() => selectType('gato')}>
              <FaCat />
            </div>
          </div>
          {/* Nombre y fecha */}
          <div className="ColDuo">
            <fieldset>
              <label>Nombre mascota</label>
              <input type="text" value={name} placeholder={'Nombre mascota'} onChange={(e) => set_name(e.target.value)} />
            </fieldset>
            <fieldset>
              <label>Nacimiento mascota</label>
              <input type="date" value={date} onChange={(e) => set_date(e.target.value)} />
            </fieldset>
          </div>
          {/* sex */}
          <div className="ColDuo">
            <div className={"FakeCheckbox" + (sex === 'H' ? " FakeCheckboxActive" : "")} onClick={() => set_sex('H')}>
              Hembra
            </div>
            <div className={"FakeCheckbox" + (sex === 'M' ? " FakeCheckboxActive" : "")} onClick={() => set_sex('M')}>
              Macho
            </div>
          </div>
          {/* Raza */}
          <div className='MID1 ZI'>
            {
              <Select
                placeholder={type ? (breed_selected ? breed_selected.name : "Seleccioná si es de raza o mestizo") : "Seleccioná el tipo de mascota"}
                onChange={(evt) => selectBreed(evt.value)}
                options={breeds_select}
                value={breed}
              />
            }
            {
              /*
              <select className='DataList' value={breed_selected} onChange={(e) => selectBreed(e.target)}>
                {breeds.map((breedrow, index) =>
                  <option value={breedrow}>{breedrow.name}</option>
                )}
              </select>
              */
            }
          </div>

          <div className="MID1 campoEmail">
            <label htmlFor="">Completá estos campos para recibir la cotización</label>
            <input placeholder={'Tu e-mail'} value={mail} onChange={(e) => set_mail(e.target.value)} />
          </div>

          <div className="MID1 campoTel">
            <label htmlFor="">+549</label>
            <input placeholder={'11 2233 1234'} value={tel} onChange={(e) => set_tel(e.target.value)} />
          </div>


        </article>
      </div>
      <div className="StickyBottomBox Botonera">
        <button className="btn btn-acttive" onClick={() => loadNavigateToPlan()}>
          {btn_title}
        </button>
      </div>
    </section>
  )
}

function Contratar({ pageStatus, context, plans, plan_selected, setPlan, profile, animal, logged, setCoverage, toggleDrawer, toggleModal }) {

  // Use effect
  useEffect(() => {
    // Validamos animal y plan
    if (!animal || !profile) {
      pageStatus('cotizar')
    }

    // Firebase Analytic
    firebase.sendToAnalyticsEvent('web_cobertura_planes')
    Analitycs.FBOliverPlusPlanViews();

    setPlan(plans[0]);
    // eslint-disable-next-line
  }, []);

  //
  const navigateToPay = () => {

    let coverage_storage = getCoverageStorage();
    let firebase_id = coverage_storage.folder_id ? firebase.getFirebaseIdByUrl('suscriptions', coverage_storage.folder_id) : null;

    let coverage = {
      animal: animal,
      profile: profile,
      plan_selected: plan_selected,
      folder_id: coverage_storage.folder_id ? coverage_storage.folder_id : null,
      firebase_id: firebase_id,
    }
    // Guardar en Firebase
    if (firebase_id) {
      firebase.update('suscriptions', firebase_id, coverage);
    } else {
      firebase.create('suscriptions', coverage);
    }

    // Guardamos Data en Storage
    setCoverageStorage(coverage);
    setCoverage(coverage);

    // Validar Login
    if (!logged) {
      window.location.href = '/seguro/registro/login';
      /*
      if (context === "Mobile") {
        window.location.href = '/registro/login';
      } else {

        // Modal login
        toggleModal()
        // Drawer
        toggleDrawer('seguro', '', false);
      }
      */
    }
    else {
      pageStatus('crear')
    }

  }

  return (
    <section className={context + ' Contratar'}>
      <NavBarSeguro back={() => pageStatus('cotizar')} />
      <div className='ContratarBody'>
        <h4>Podés cuidar a {animal.name} con cualquiera de estas dos increíbles coberturas</h4>
        {plans.map((plan, index) =>
          <div key={index} className={"Card " + (plan === plan_selected ? " CardSelected" : "")} onClick={() => setPlan(plan)}>
            <h3>Plan {plan.nombre} (*): ${plan.precio} <span> ${plan.precio_lista} </span> </h3>
            <p>Por mascota</p>
            {plan.caracteristicas.map((caract, o) =>
              <div className="RowCenter" key={o}>
                <FaCheckCircle />
                <span>{caract}</span>
              </div>
            )}
            <div style={{ marginTop: 10 }}>
              <a style={{ fontSize: ".8rem" }} href={plan.terminos_condiciones} target="_blank" rel="noopener noreferrer">ver términos y condiciones</a>
            </div>
          </div>
        )}
      </div>
      <div className="StickyBottomBox Botonera">
        <button className="btn btn-acttive" onClick={() => navigateToPay()}>
          Contratar
        </button>
      </div>
    </section>
  )
}

function Crear({ pageStatus, context, coverage, setCoverage, history, locationHistory, domicilio, setDomicilio, responseCoverage, setResponseCoverage, setAnimalEntity }) {

  const [showPaymentMethod, setShowPaymentMethod] = useState(''); // Tarjeta a mostrar - ID de seleccionada o Token de Nueva
  const [cardData, setCardData] = useState(null);
  const [installment_id, setInstallment_id] = useState();
  const [installment, setInstallment] = useState();
  const [issuer_id, setIssuer_id] = useState();
  // List cards
  const [cards, setCards] = useState([]); // Lista de tarjetas
  const [cardSelectedCVV, setCardSelectedCVV] = useState(''); // Tarjeta seleccionada CVV
  const [cardSelected, setCardSelected] = useState(null); // Tarjeta nueva
  const [loading, setLoading] = useState(true);
  const [cuotas, setCuotas] = useState([]);
  // Costos financiero
  const [costo_financiero, setCosto_financiero] = useState(0);
  const [cuota_valor, setCuota_valor] = useState(null);
  const [cuota_cantidad, setCuota_cantidad] = useState(null);
  const [payment_type_id, setPayment_type_id] = useState(null);
  // Modal Generic
  const [selectCard, setSelectCard] = useState(); // Tarjeta seleccionada

  // Procesapago
  const [checked, setChecked] = useState(false);
  const [processCoverage, setProcessCoverage] = useState(false);
  const [showAnimation, setShowAnimation] = useState(false);
  const animalAnimation = coverage.animal;
  const totalAmountProd = coverage.plan_selected.precio;
  // Use effect
  useEffect(() => {
    getPaymentData();

    // Firebase Analytic
    firebase.sendToAnalyticsEvent('web_cobertura_formulario_datos')

    // eslint-disable-next-line
  }, []);

  // Get Data logueado
  const getPaymentData = async () => {

    // profile
    let UserData = await OliverHelper.ClientAPI.getProfileData()
    var params = {
      user_lastname: UserData.payload.lastname,
      email: UserData.payload.email,
      user_name: UserData.payload.name,
      password: "",
      rePassword: "",
      phone: coverage.profile.phone ? coverage.profile.phone : (UserData.phone ? UserData.phone : ""),
      cod_area: UserData.payload.phone_cod_area ? UserData.payload.phone_cod_area : "011",
      dni: coverage.profile.dni ? coverage.profile.dni : (UserData.payload.dni ? UserData.payload.dni : ""),
    }

    // domicilio
    let domicilio_aux = await OliverHelper.getDomicilio();
    setDomicilio(domicilio_aux);

    chnageProfile(params);
    setLoading(false);
  }

  // Profile
  const setAttrProfile = (attr, value) => {
    let dataProfile = coverage.profile;
    dataProfile[attr] = value
    chnageProfile(dataProfile)
  }

  const chnageProfile = (dataProfile) => {
    let aux = {
      animal: coverage.animal,
      profile: dataProfile,
      plan_selected: coverage.plan_selected
    }
    setCoverage(aux)
  }

  // Domicilio
  const changeDomicilio = () => {
    var routeName = window.location.pathname

    // Guardamos Data en Storage
    setCoverageStorage(coverage);

    if (context === 'isMobile') {
      history.push({ pathname: '/seguro-nueva-direccion', navigationBack: routeName, cardData: cardData })
    } else {
      // *** Aca falta la navegacion para desktop en el drawer
      history.push({ pathname: '/seguro-nueva-direccion', navigationBack: routeName, cardData: cardData })
    }
  }

  // --- --- CARDS MP

  // ...

  // --- --- SEND DATA

  const validateToSend = () => {

    // ...

    // Validar Data profile
    if (!coverage.profile.user_name) {
      alert("Debe ingresar su nombre")
      return false
    }

    if (!coverage.profile.user_lastname) {
      alert("Debe ingresar su apellido")
      return false
    }

    if (!coverage.profile.email) {
      alert("Debe ingresar su email")
      return false
    }
    if (!OliverHelper.validatePhoneNumber(coverage.profile.phone)) {
      alert("Debe ingresar un teléfono válido")
      return false
    }

    if (!coverage.profile.dni) {
      alert("Debe ingresar su dni")
      return false
    }

    if (!coverage.profile.dni) {
      alert("Debe ingresar su dni")
      return false
    }

    return true
  }

  const validateAddres = () => {
    if (domicilio !== null) {
      setChecked(!checked)
    } else {
      alert('Parece que no completaste el Domicilio, esta información es necesaria ')
    }
  }

  const registerCoverage = () => {

    // Validar data
    if (!validateToSend()) return;

    setProcessCoverage(true);

    // Data recolectada
    let data = {
      profile: coverage.profile,
      animal: coverage.animal,
      plan_selected: coverage.plan_selected,
      domicilio: domicilio,
      //card: cardData
    }

    //console.log("DATA A ENVIAR: ", data);
    //return null;

    OliverHelper.ClientAPI.createSuscription(data).then(async (response) => {
      console.log("RESPONSE: ", response);
      if (response.status) {

        setProcessCoverage(false);
        setResponseCoverage(true);

        // Actualizar storage
        let coverage_storage = getCoverageStorage();
        let firebase_id = coverage_storage.folder_id ? firebase.getFirebaseIdByUrl('suscriptions', coverage_storage.folder_id) : null;

        let coverage_aux = {
          animal: data.animal,
          profile: data.profile,
          plan_selected: data.plan_selected,
          domicilio: data.domicilio,
          folder_id: coverage_storage.folder_id ? coverage_storage.folder_id : null,
          firebase_id: firebase_id,
          status_contact: 1,
          try_to_pay: true
        }

        // Guardamos Auxiliar
        setCoverageStorageAso(coverage_aux);

        // Limpiamos data en storage
        setCoverageStorage(null);

        setAnimalEntity(response.payload.data);
        pageStatus('asociar')

      } else {

        setProcessCoverage(false);
        setResponseCoverage(false);

        alert('Hubo un problema al cargar los datos')
      }
    })
  }

  // --- --- Animation Response

  const continueFunction = () => {
    window.location.replace("https://mispichos.com")
    //history.push({ pathname: '/', state: null })
    //history.go();
  }

  const retryFunction = () => {
    setShowAnimation(false);
    registerCoverage()
  }

  const changeFunction = () => {
    setShowAnimation(false);
  }

  // --- --- RENDER

  if (loading) {
    return (<LoadingOmar />)
  }

  if (showAnimation) {
    return responseCoverage ?
      <AnimacionSeguroAprobed continueFunction={continueFunction} animal={animalAnimation} />
      :
      <AnimacionSeguroDeclined retryFunction={retryFunction} changeFunction={changeFunction} />
  }
  console.log(cardSelected)
  return (
    <section className={context + ' Pagar'}>
      <NavBarSeguro back={() => pageStatus('contratar')} />
      <div className='FormPagar'>
        <br />
        <div className="Card P2">
          <p>Datos del titular</p>
          <br />
          <article>
            <div className="ColDuo">
              <fieldset>
                <legend>Nombre<span>*</span></legend>
                <input type="text" value={coverage.profile.user_name} onChange={(e) => setAttrProfile('user_name', e.target.value)} />
              </fieldset>
              <fieldset>
                <legend>Apellido<span>*</span></legend>
                <input type="text" value={coverage.profile.user_lastname} onChange={(e) => setAttrProfile('user_lastname', e.target.value)} />
              </fieldset>
            </div>
            <fieldset>
              <legend>Email<span>*</span></legend>
              <input type="text" value={coverage.profile.email} onChange={(e) => setAttrProfile('email', e.target.value)} />
            </fieldset>
            <div className="ColDuo">
              <fieldset>
                <legend>DNI<span>*</span></legend>
                <input type="text" value={coverage.profile.dni} onChange={(e) => setAttrProfile('dni', e.target.value)} />
              </fieldset>
              <fieldset>
                <legend>Teléfono<span>*</span></legend>
                <input type="text" value={coverage.profile.phone} onChange={(e) => setAttrProfile('phone', e.target.value)} />
              </fieldset>
            </div>

            <button onClick={() => changeDomicilio()}>
              {domicilio ? domicilio.dirececionresumida : "Ingresar el domicilio de " + coverage.animal.name}
            </button>

            <a href={coverage.plan_selected.terminos_condiciones} target="_blank" rel="noopener noreferrer">ver términos y condiciones del plan {coverage.plan_selected.nombre} </a>

            <div className="CheckBox">
              <input type="checkbox" id="cbox1" checked={checked} onClick={() => validateAddres()} />
              <label>He leído y acepto los Términos y Condiciones y confirmo que mi mascota tiene las vacunas al dia.</label>
            </div>
          </article>
        </div>
      </div>
      <div className="StickyBottomBox Botonera">
        <button className={"btn" + (checked ? " btn-acttive" : "")} onClick={() => checked && (!processCoverage && registerCoverage())}>
          {processCoverage ? "Procesando..." : "Pagar"}
        </button>
      </div>
    </section>
  )
}

function Asociar({ pageStatus, context, history, locationHistory, responseCoverage, setResponseCoverage, AnimalEntity }) {

  const [showPaymentMethod, setShowPaymentMethod] = useState(''); // Tarjeta a mostrar - ID de seleccionada o Token de Nueva
  const [cardData, setCardData] = useState(null);
  const [installment_id, setInstallment_id] = useState();
  const [installment, setInstallment] = useState();
  const [issuer_id, setIssuer_id] = useState();
  // List cards
  const [cards, setCards] = useState([]); // Lista de tarjetas
  const [cardSelectedCVV, setCardSelectedCVV] = useState(''); // Tarjeta seleccionada CVV
  const [cardSelected, setCardSelected] = useState(null); // Tarjeta nueva
  const [loading, setLoading] = useState(true);
  const [cuotas, setCuotas] = useState([]);
  // Costos financiero
  const [costo_financiero, setCosto_financiero] = useState(0);
  const [cuota_valor, setCuota_valor] = useState(null);
  const [cuota_cantidad, setCuota_cantidad] = useState(null);
  const [payment_type_id, setPayment_type_id] = useState(null);
  // Modal Generic
  const [selectCard, setSelectCard] = useState(); // Tarjeta seleccionada

  // Procesapago
  const [checked, setChecked] = useState(true);
  const [processCoverage, setProcessCoverage] = useState(false);
  const [showAnimation, setShowAnimation] = useState(false);
  const animalAnimation = AnimalEntity;
  const totalAmountProd = AnimalEntity.suscription.monto;
  // Use effect
  useEffect(() => {

    getCards();

    // Si viene por formulario nueva tarjeta
    if (locationHistory.state && locationHistory.state.card !== undefined && locationHistory.state.card !== null) {
      getMobileCardData(locationHistory.state.card)
    }

    // eslint-disable-next-line
  }, []);

  // --- --- CARDS MP

  // Get cards
  const getCards = async () => {

    // Lista Tarjetas
    var tarj = []
    var card = null
    var showcard = null

    await OliverHelper.ClientAPI.getCards().then((response) => {
      if (response && response.payload) {
        if (response.payload.status) {
          tarj = response.payload.data

          // Tarjeta por Defecto
          if (!locationHistory.state || locationHistory.state.card === undefined) {
            showcard = response.payload.data[0].id
            card = response.payload.data[0]
          }
        } else {
          tarj = []
        }
      } else {
        tarj = []
      }
    }).catch(() => tarj = []);

    // Selecciona Tarjeta por Defecto
    if (card && showcard) {

      //setUserData(params);
      setLoading(false);
      // Lista tarjetas
      setCards(tarj);
      setSelectCard(card);
      //setShowPaymentMethod(showcard);

      //getInstallmentsSelectCard(card);
    } // No Selecciona Tarjeta por Defecto
    else {
      //setUserData(params);
      setLoading(false);
      // Lista tarjetas
      setCards(tarj);
    }
  }

  const cardToShow = () => {
    var routeName = window.location.pathname

    if (context === 'isMobile') {
      history.push({ pathname: '/seguro-card-payment', state: { total: totalAmountProd, navigationBack: routeName, step: 'asociar', AnimalEntity: AnimalEntity } })
    } else {
      // *** Aca falta la navegacion para desktop en el drawer
      history.push({ pathname: '/seguro-card-payment', state: { total: totalAmountProd, navigationBack: routeName, step: 'asociar', AnimalEntity, AnimalEntity } })
    }
  }

  const getMobileCardData = (card) => {
    //var cardOject = card.cardObject;
    setSelectCard(null);
    setCardData(card);
    setCardSelected(card);
    setShowPaymentMethod(card.token);
  }

  // Cuotas
  const getInstallmentsSelectCard = async (data) => {

    let monto = totalAmountProd;
    let response = await mercadoPago.getPaymentMethodsInstallments(data.first_six_digits, monto);

    // Cuotas Mispichos
    var cuotas = misPichosInstallments(response[0].payer_costs);

    // Formatear Data Select
    var Aux = []
    cuotas.map((eachDues) => (
      Aux.push({ value: eachDues.installments, label: eachDues.recommended_message })
    ))

    // Tarjeta de Debito
    if (response[0].payment_type_id === 'debit_card') {
      setCuotas([cuotas[0]]);
      setIssuer_id(response[0].issuer.id);
      setPayment_type_id(response[0].payment_type_id);
      // Cuota por defecto
      setInstallment_id(cuotas[0].installments);
      setInstallment(cuotas[0]);
      // Card selected
      setSelectCard(data);
      setShowPaymentMethod(data.id);

      sendSelectCard(data);
    }
    // Tarjeta de Credito
    else {

      setCuotas(cuotas);
      setIssuer_id(response[0].issuer.id);
      setPayment_type_id(response[0].payment_type_id);
      // Cuota por defecto
      setInstallment_id(cuotas[0].installments);
      setInstallment(cuotas[0]);
      // Card selected
      setSelectCard(data);
      setShowPaymentMethod(data.id);

      sendSelectCard(data);
    }
  }

  const misPichosInstallments = (mercadopagoCuotas) => {
    var monto = totalAmountProd;

    // --- Logica
    var costo_financiero = 0

    // ARG
    if (country === 'MLA') {
      if (monto < 54500) {
        costo_financiero = 0.1;
      } else {
        costo_financiero = 0;
      }
    }

    // 3 cuotas
    var monto_costo_financiero = truncNumber2(monto * costo_financiero);
    var monto_total = truncNumber2(monto_costo_financiero + monto);
    var monto_cuota = truncNumber2(monto_total / 3);

    var cuotas = [
      {
        installments: 1,
        recommended_message: "1 " + translate.t('selectInstalement') + " de $ " + NumberFormatJs(monto, 2, ",", ".") + " ($ " + NumberFormatJs(monto, 2, ",", ".") + ")",
        total_amount: monto,
        monto_base: monto,
        costo_financiero: 0,
        monto_cuota: monto,
      },
      {
        installments: 3,
        recommended_message: "3 " + translate.t('selectInstalement') + " de $ " + NumberFormatJs(monto_cuota, 2, ",", ".") + " ($ " + NumberFormatJs(monto_total, 2, ",", ".") + ")",
        total_amount: monto_total,
        monto_base: monto,
        costo_financiero: monto_costo_financiero,
        monto_cuota: monto_cuota,
      }
    ]

    // Validacion de 3era cuota mercadopago
    var mpvalidacion = false;
    mercadopagoCuotas.map((cuota, index) => {
      //console.log("Validacion de montos:", index, monto, cuota.total_amount, cuota.total_amount == monto);
      if (cuota.installments === 3 && cuota.total_amount === monto) {
        mpvalidacion = true;
      }
      return mpvalidacion
    })

    // 1 - 3 Cuotas
    if (mpvalidacion) return cuotas;

    // 1 - Cuotas
    return [cuotas[0]];
  }

  const truncNumber2 = (number) => {
    return Math.trunc(number * 100) / 100;
  }

  // Set CVV
  const setCvv = (value) => {
    setCardSelectedCVV(value);
    value.length > 2 && sendSelectCard(selectCard, value)
  }

  const sendSelectCard = async (selectCard, cvv_selected = null) => {

    let paramsCard = { card_id: selectCard.id };
    if (cvv_selected) paramsCard.securityCode = cvv_selected;

    let response = await mercadoPago.postCardToken(paramsCard);

    if (response.status === 'active') {

      let card = {
        // card
        payment_method_id: selectCard.payment_method.id,
        cardholder: selectCard.cardholder,
        payer_id: selectCard.payer_id,
        last_four_digits: selectCard.last_four_digits,
        token: response.id,
        id: response.card_id ? response.card_id : selectCard.id,
        //
        installment_id: installment_id,
        installment: installment,
        issuer_id: issuer_id,
      }

      var cvv = cvv_selected
      if (selectCard.id !== showPaymentMethod) {
        cvv = ''
      }

      // Installment
      if (installment) {
        var costo_financiero = installment.costo_financiero ? installment.costo_financiero : 0;
        var cuota_valor = installment.monto_cuota ? installment.monto_cuota : 0;
        var cuota_cantidad = installment.installments ? installment.installments : 0;

        setCosto_financiero(costo_financiero);
        setCuota_valor(cuota_valor);
        setCuota_cantidad(cuota_cantidad);
      }

      setCardData(card);
      setSelectCard(selectCard);
      //setMpago(2);
      //setShowPaymentMethod(selectCard.id);
      console.log("------- parametro cvv: ", cvv)
      setCardSelectedCVV(cvv);
    } else {
      console.log('response error', response);
    }
  }

  // --- --- SEND DATA

  const validateToSend = () => {

    // Validar CVV
    if (selectCard) {
      if (cardSelectedCVV < 3) {
        alert("Debes ingresar el CVV de tu tarjeta seleccionada")
        return false
      }
    }

    // Validar Tarjeta
    if (!cardData) {
      alert("Selecciona una tarjeta o agrega una nueva")
      return false
    }

    // Validar Tarjeta token
    if (!cardData.token) {
      alert("Problemas con la tarjeta, revisa que los datos ingresados sean correctos. Si el problema persiste comunicate con nosotros.")
      return false
    }

    return true
  }

  const registerCoverage = () => {

    // Validar data
    if (!validateToSend()) return;

    setProcessCoverage(true);

    // Data recolectada
    let data = {
      suscripcion: AnimalEntity.suscription.id,
      card: cardData
    }

    //console.log("DATA A ENVIAR: ", data);
    //return null;

    // Firebase Analytic
    firebase.sendToAnalyticsEvent('web_cobertura_press_finalizar')

    OliverHelper.ClientAPI.asociateSuscription(data).then(async (response) => {
      console.log("RESPONSE: ", response);
      if (response.status) {

        // Firebase Analytic
        firebase.sendToAnalyticsEvent('web_cobertura_generada')

        Analitycs.FBSuscription({ sus_id: response.payload.data.id, total: response.payload.data.monto });

        // Actualizar en Firebase
        let coverage_storage = getCoverageStorageAso();
        if (coverage_storage) {

          let firebase_id = coverage_storage.firebase_id ? coverage_storage.firebase_id : null;

          let coverage_aux = {
            animal: coverage_storage.animal,
            profile: coverage_storage.profile,
            plan_selected: coverage_storage.plan_selected,
            domicilio: coverage_storage.domicilio,
            folder_id: coverage_storage.folder_id ? coverage_storage.folder_id : null,
            firebase_id: firebase_id,
            status_contact: 1,
            try_to_pay: true
          }

          // Guardar en Firebase
          if (firebase_id) {
            firebase.update('suscriptions', firebase_id, coverage_aux);
          } else {
            firebase.create('suscriptions', coverage_aux);
          }

        }

        setProcessCoverage(false);
        setResponseCoverage(true)
        setShowAnimation(true);

        // Limpiamos data en storage
        setCoverageStorage(null);

        // Limpiaos param navigation para evitar problema al recargar
        history.replace('', null);

      } else {

        // Firebase Analytic
        firebase.sendToAnalyticsEvent('web_cobertura_falla_pago')

        // Actualizar en Firebase
        let coverage_storage = getCoverageStorageAso();
        if (coverage_storage) {

          let firebase_id = coverage_storage.firebase_id ? coverage_storage.firebase_id : null;

          let coverage_aux = {
            animal: coverage_storage.animal,
            profile: coverage_storage.profile,
            plan_selected: coverage_storage.plan_selected,
            domicilio: coverage_storage.domicilio,
            folder_id: coverage_storage.folder_id ? coverage_storage.folder_id : null,
            firebase_id: firebase_id,
            try_to_pay: true
          }
          // Guardar en Firebase
          if (firebase_id) {
            firebase.update('suscriptions', firebase_id, coverage_aux);
          } else {
            firebase.create('suscriptions', coverage_aux);
          }
        }

        setProcessCoverage(false);
        setResponseCoverage(false)
        setShowAnimation(true);
      }
    })


  }

  // --- --- Animation Response

  const continueFunction = () => {
    window.location.replace("https://mispichos.com")
    //history.push({ pathname: '/', state: null })
    //history.go();
  }

  const retryFunction = () => {
    setShowAnimation(false);
    registerCoverage()
  }

  const changeFunction = () => {
    setShowAnimation(false);
  }

  const getIconByCard = (payment_method_id) => {
    return payment_method_id === "visa" ? 'visa' : (payment_method_id === "american" ? 'american-express' : (payment_method_id === "master" ? 'mastercard' : 'placeholder'))
  }


  // --- --- RENDER

  if (loading) {
    return (<LoadingOmar />)
  }

  if (showAnimation) {
    return responseCoverage ?
      <AnimacionSeguroAprobed continueFunction={continueFunction} animal={animalAnimation} />
      :
      <AnimacionSeguroDeclined retryFunction={retryFunction} changeFunction={changeFunction} />
  }
  console.log(cardSelected)
  return (
    <section className={context + ' Pagar'}>
      <NavBarSeguro back={() => pageStatus('petList')} />
      <div className='FormPagar'>
        <br />
        <h3 style={{ textAlign: 'center' }}>¡Ya casi..!</h3>
        <div className="Card">
          <p>Cobertura de {AnimalEntity.name}<br /><br />
            <strong>Estás por pagar ${AnimalEntity.suscription.monto} </strong>
            y el monto se debitará mensualmente de la tarjeta que ingreses a continuación
          </p>

          <ol className='ListaTarjetas'>
            {cards &&
              cards.map((card, index) =>
                <li className={showPaymentMethod === card.id ? 'active' : null} onClick={() => getInstallmentsSelectCard(card)}>
                  <div className="RowCenter">
                    <img src={Icons[getIconByCard(card.payment_method.id)]} alt={''} />
                    <span>Terminada en {card.last_four_digits}</span>
                  </div>
                  {showPaymentMethod === card.id && <input type='text' autoFocus={true} placeholder='CVV' value={cardSelectedCVV} onChange={(value) => setCvv(value.target.value)} />}
                </li>
              )
            }
            {cardSelected &&

              <li className={showPaymentMethod === cardSelected.token ? 'active' : ''} onClick={() => getMobileCardData(cardSelected)}>
                <div className="RowCenter">
                  <img src={Icons[getIconByCard(cardSelected.payment_method_id)]} alt={cardSelected.payment_method_id} />
                  <span>Terminada en {cardSelected.last_four_digits}</span>
                </div>
              </li>
            }
          </ol>
          <button onClick={() => cardToShow()}>
            Ingresar tarjeta
          </button>
        </div>

      </div>
      <div className="StickyBottomBox Botonera">
        <button className={"btn" + (checked ? " btn-acttive" : "")} onClick={() => checked && (!processCoverage && registerCoverage())}>
          {processCoverage ? "Procesando..." : "Pagar"}
        </button>
      </div>
    </section>
  )
}

function Info({ pageStatus, context, history, animal }) {

  return (
    <section className='AnimacionSeguro Aproved'>
      <img src={imageLogo} alt="" className='imageLogo' />
      <h4>{animal.name} cuenta con Oliver Plus</h4>
      <div className='ShieldBox'>
        <FaShieldAlt className='Shield' />
        <FaHeart className='Heart' />
      </div>
      <span>Ahora está más segur{animal.sex === 'H' ? "a" : "o"}</span>
      <br />
      <div className='appBox'>
        <h4>¡Recordá gestionar tu cobertura desde la app!</h4>
        <div className="RowCenter">
          <img onClick={() => history.push('https://play.google.com/store/apps/details?id=com.mispichos.petshops&hl=es_AR')} src={applestore} lazy alt="" className='imageLogo' />
          <img onClick={() => history.push('https://apps.apple.com/us/app/partner-manager/id1540778692?l=es')} src={playstore} lazy alt="" className='imageLogo' />
        </div>
      </div>
      <button className='Continuar' onClick={() => pageStatus("petList")}>Volver</button>
    </section>
  )

  return (
    <section className={context}>
      <h1>Gestiona seguro desde nuestra app!</h1>
      <button className="btn" onClick={() => pageStatus("petList")}>
        Volver
      </button>
    </section>
  )
}

// --- Funciones Local Storage

function setCoverageStorage(coverage) {
  localStorage.setItem("coverage", JSON.stringify(coverage));
}

function getCoverageStorage() {
  // retorna null si no existe
  let coverage = JSON.parse(localStorage.getItem("coverage"))
  return coverage
}

function setCoverageStorageAso(coverage) {
  localStorage.setItem("coverage_asociacion", JSON.stringify(coverage));
}

function getCoverageStorageAso() {
  // retorna null si no existe
  let coverage = JSON.parse(localStorage.getItem("coverage_asociacion"))
  return coverage
}

// --- Nav Bar Seguro

const NavBarSeguro = ({ back }) => {

  return (
    <nav className='MobileNavbar RowCenter NavbarSeguro'>
      <Suspense fallback={<LoadingNav />}>
        {back ?
          <button className='NavbarBackIcon' onClick={() => back()}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </button>
          :
          <div style={{ width: '32px', marginRight: '8px' }} />
        }
        <a href="https://mispichos.com" target="_blank" rel="noopener noreferrer">
          <img src={MisPichoslogo} alt="" className='OliverLogo' />
        </a>
        <div style={{ width: '32px', marginRight: '8px' }} />
      </Suspense>
    </nav>
  )
}

export default withRouter(Seguro);
export { setCoverageStorage, getCoverageStorage, NavBarSeguro }
