import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faStarHalfAlt, faTimes } from '@fortawesome/free-solid-svg-icons'
import { isIOS } from 'react-device-detect';
import OliverHelper from '../../utils/Oliverhelper';

export default class BajateLaApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: true
    }
  }


  goToStore = () => {
    if (isIOS) {
      window.open('https://apps.apple.com/us/app/mispichos/id1488224504?l=es')
    } else {
      window.open('https://play.google.com/store/apps/details?id=com.mispichos.clientes&hl=es_AR')
    }
  }

  render() {
    if (this.state.active) {
      return (
        <article className='BajateLaApp' style={{ display: 'none' }}>
          <img src={require('../../IMG/icon-oliver.png')} alt="" />
          <div onClick={this.goToStore}>
            <h4>Descarga la app</h4>
            <p>Compra más fácil y rápido</p>
            <div className="RowCenter Estrellas">
              <FontAwesomeIcon icon={faStar}/>
              <FontAwesomeIcon icon={faStar}/>
              <FontAwesomeIcon icon={faStar}/>
              <FontAwesomeIcon icon={faStar}/>
              <FontAwesomeIcon icon={faStarHalfAlt}/>
            </div>
          </div>
          <FontAwesomeIcon icon={faTimes} onClick={() => {OliverHelper.hidePopupApp()}} />
        </article>
      )
    } else {
      return (null)
    }
  }
}
