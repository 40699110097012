import React from 'react';
import { AiOutlineLoading } from "react-icons/ai";
import imageLogo from '../../IMG/seguro/oliver_plus_fondo_azul.jpg';
import { FaHeart, FaShieldAlt } from "react-icons/fa";
import './seguro.css';

import playstore from '../../IMG/logo-google-store.png';
import applestore from '../../IMG/logo-apple-store.png';
import { useHistory } from 'react-router';


function AnimacionSeguroDeclined({ retryFunction, changeFunction }) {
  return (
    <section className='AnimacionSeguro declined'>
      <h4>No se registró el pago</h4>
      <p>Hubo un problema al registrar el pago</p>
      <button onClick={() => retryFunction()}>
        Reintentar
      </button>
      <button onClick={() => changeFunction()}>
        Cambiar de tarjeta
      </button>
      {/*<a href="#">Solicitar Ayuda</a>*/}
    </section>
  )
}

function AnimacionSeguroAprobed({ continueFunction, animal }) {

  let history = useHistory();

  return (
    <section className='AnimacionSeguro Aproved'>
      <img src={imageLogo} alt="" className='imageLogo' />
      <h4>Bienvenid{animal.sex === 'H' ? "a" : "o"} {animal.name} a Oliver Plus</h4>
      <div className='ShieldBox'>
        <FaShieldAlt className='Shield' />
        <FaHeart className='Heart' />
      </div>
      <span>Ahora está más segur{animal.sex === 'H' ? "a" : "o"}</span>
      <br />
      <div className='appBox'>
        <h4>¡Recordá gestionar tu cobertura desde la app!</h4>
        <div className="RowCenter">
          <img onClick={() => history.push('https://play.google.com/store/apps/details?id=com.mispichos.petshops&hl=es_AR')} src={applestore} lazy alt="" className='imageLogo' />
          <img onClick={() => history.push('https://apps.apple.com/us/app/partner-manager/id1540778692?l=es')} src={playstore} lazy alt="" className='imageLogo' />
        </div>
      </div>
      <button className='Continuar' onClick={() => continueFunction()}>Volver al Inicio</button>
    </section>
  )
}

function LoadingSeguro() {
  return (
    <section className='LoadingSeguro'>
      <AiOutlineLoading className='Giro360' />
    </section>
  )
}

export { AnimacionSeguroDeclined, AnimacionSeguroAprobed, LoadingSeguro }