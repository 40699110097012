import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import OliverHelper from '../../utils/Oliverhelper';
import { withRouter } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import translate from '../../languages'

class SearchBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showRequestBox: false, value: '', results: []
    }
    this.timeout = 0;
  }

  componentWillReceiveProps(next_props) {
    if (next_props.searchFocus !== this.props.searchFocus) {
      this.setState({ showRequestBox: next_props.searchFocus })
    }
  }


  searchText = (event) => {
    if (event) this.setState({ value: event.target.value });

    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      OliverHelper.ClientAPI.getSearch({ search: this.state.value }).then((response) => {
        if (response && response.payload !== undefined) {
          this.setState({ results: response.payload.results })
        }
      }).catch(error => { alert('Problemas con el Buscador' + error); })
    }, 500);

  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.goToPord()
  }

  transformStringToUriString = (value) => {
    let newValue = value
    newValue = newValue.replace(/ /g, "-")
    newValue = newValue.replace(/\//g, "")
    console.log("CHANGE: ", value, newValue)
    return newValue;
  }

  createLinkToProduct = (dataProducto) => {
    var security = window.location.pathname.substr(0, 10)
    let catName = dataProducto.cat_nombre ? this.transformStringToUriString(dataProducto.cat_nombre) : null;
    let brandName = dataProducto.mar_nombre ? this.transformStringToUriString(dataProducto.mar_nombre) : null;
    let prodName = dataProducto.pro_nombre ? this.transformStringToUriString(dataProducto.pro_nombre) : null;
    let proId = (dataProducto.pro_id);
    this.setState({ showRequestBox: false })
    this.props.history.push('/productos/' + catName + '/' + brandName + '/' + prodName.replace("/", "-").replace("/", "-").replace("/", "-") + '/' + proId);
    if (!isMobile && security === "/productos") {
      return this.props.history.go();
    }
  }

  cleanSearch = () => {
    if (isMobile) {
      this.setState({ showRequestBox: false, value: '' }, () =>
        this.nameInput.blur(),
        this.searchText()
      )
    } else {
      this.setState({ showRequestBox: false, value: '', results: [] }, () => this.nameInput.blur())
    }
  }

  goToPord = () => {
    this.props.history.push({
      pathname: "/productos",
      search: '?busqueda=' + this.state.value.replace(/ /g, "-"),
      state: ' de ' + this.state.value
    })
    return this.props.history.go();
  }


  render() {
    this.props.searchFocus && this.nameInput.focus()
    console.log("this.state.showRequestBox", this.state.showRequestBox)
    return (
      <form className='SearchBox' onSubmit={this.state.value !== '' && this.handleSubmit}>
        <div className='InputSearchBox RowCenter' onClick={() => {
          this.nameInput.focus()
          this.props.showDrawer && this.props.showDrawer('search')
        }}>
          <input
            ref={(input) => { this.nameInput = input; }}
            placeholder={translate.t('searchbox')}
            type="text"
            value={this.state.value}
            onChange={this.searchText}
          />

          {this.state.showRequestBox ?
            <button type="reset" onClick={() => this.cleanSearch()}>
              <FontAwesomeIcon icon={faTrashAlt} />
            </button>
            :
            <button type="submit" value="Submit">
              <FontAwesomeIcon icon={faSearch} />
            </button>
          }


          {!this.state.showRequestBox ?
            <div className={'btn-ShowSearchbox'} onClick={() => { this.setState({ showRequestBox: true }) }} />
            :
            null
          }

        </div>
        <ol className={this.state.showRequestBox ? 'ShowElement' : ''}>
          {this.state.results.map((prod) =>
            <li key={prod.pro_id} className='RowCenter AlignCenter' onClick={() => this.createLinkToProduct(prod)}>
              <div className='ImageBox'>
                <img src={prod.foto_src} alt={''} />
              </div>
              <div className='LabelBox'>
                <h4>{prod.mar_nombre}</h4>
                <p>
                  {prod.pro_nombre}
                  <br />
                  <span>Desde: ${prod.precio_minimo}</span>
                </p>
              </div>
            </li>
          )}
        </ol>
      </form >
    );
  }
}

export default withRouter(SearchBox)
