import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faUser, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
//import { isMobile } from 'react-device-detect'
import { withRouter } from 'react-router-dom'


class PreguntasFrecuentes extends React.Component {
  render() {
    return (
      <section className='Vendedores'>
        <article className='bloqueUno'>
          <div>
            <h1>Potenciá tu pet shop</h1>
            <span>Ganá ventas en internet las 24hs.</span>
            <p>El primer sistema de ventas online que trabaja en conjunto con veterinarias y pet shops asociados. Somos especialistas en venta online de productos para perros y gatos. Empezá a vender hoy a cientos de clientes que compran por internet, sin tener que hacer ninguna inversión.</p>
          </div>
          <img className='tiendita' alt='Imagen de una tienda' src={'https://mispichos.com/public/landing/images/image1.png'} />
        </article>
        <article className='bloqueDos'>
          <img className='nubes' alt='Imagen de nubes' src={require('../../IMG/vete-nubes.png')} />
          <h1>Mirá como funciona<br />¡Es muy fácil!</h1>
          <div>
            <img className='left' alt='Imagen de nubes' src={require('../../IMG/vete-nubes-left.png')} />
            <iframe title='video MP' src="https://www.youtube.com/embed/DkdHuTWasVg" frameborder="0" allowfullscreen=""></iframe>
            <img className='right' alt='Imagen de nubes' src={require('../../IMG/vete-nubes-right.png')} />
          </div>
          <ol>
            <li>
              <img alt='' src={'https://mispichos.com/public/landing/images/image3.png'} />
              <span>Te registrás y cargás tus productos</span>
            </li>
            <li>
              <img alt='' src={'https://mispichos.com/public/landing/images/image4.png'} />
              <span>Nuestros clientes compran tus productos. Vos recibís una alerta</span>
            </li>
            <li>
              <img alt='' src={'https://mispichos.com/public/landing/images/image-5.png'} />
              <span>Envías los productos al cliente</span>
            </li>
            <li>
              <img alt='' src={'https://mispichos.com/public/landing/images/image6.png'} />
              <span>El cliente te paga en el domicilio. Luego pagás una comision por el servicio.</span>
            </li>
          </ol>
        </article>
        <article className='bloqueTres'>
          <ol>
            <li>
              <img alt='' src='https://mispichos.com/public/landing/images/image7.png' />
              <h2>SIN RIESGOS</h2>
              <p>Aumenta tus ventas sin inversiones</p>
            </li>
            <li>
              <img alt='' src='https://mispichos.com/public/landing/images/image-8.png' />
              <h2>SIN COSTO DE MANTENIMIENTO</h2>
              <p>Sin costo de adhesión ni mantenimiento</p>
            </li>
            <li>
              <img alt='' src='https://mispichos.com/public/landing/images/image9.png' />
              <h2>SIN TIEMPO MÍNIMO DE PERMANENCIA</h2>
              <p>Podes dejar de vender con nosotros cuando quieras. Sin costo y con solo un click</p>
            </li>
          </ol>
        </article>
        <div className='versatil'>
          <img className='dotor' alt='' src='https://mispichos.com/public/landing/images/services.png' />
          <article className='bloqueCuatro'>
            <h1>
              El avance del ecommerce
            </h1>
            <ol>
              <li>
                <span>3.000.000</span>
                <p>compradores en CABA + GBA</p>
              </li>
              <li>
                <span>51%</span>
                <p>Creció la venta online en 2020</p>
              </li>
              <li>
                <span>70%</span>
                <p>Comprá en páginas que reúnen</p>
              </li>
            </ol>
          </article>
          <article className='bloqueCinco'>
            <h1>Ponemos al servicio<br />de tu pet shop</h1>
            <ol>
              <li>
                <img alt='' src='https://mispichos.com/public/landing/images/services-t.png' />
                <span>
                  Tecnología
                </span>
                <p>
                  Contamos con un avanzado sistema
                  que te permite vender a cientos
                  de clientes que están en TU
                  zona de entrega.
                </p>
              </li>
              <li>
                <img alt='' src='https://mispichos.com/public/landing/images/services-p.png' />
                <span>
                  Publicidad
                </span>
                <p>
                  Gran presencia en Google,
                  Facebook, Instagram y otros medios
                  digitales. La mayor inversión en
                  marketing online en el rubro mascotas.
                </p>
              </li>
              <li>
                <img alt='' src='https://mispichos.com/public/landing/images/services-s.png' />
                <span>
                  Servicio
                </span>
                <p>
                  Disponemos de un centro
                  de atención al cliente que
                  garantiza una experiencia
                  de compra superior.
                </p>
              </li>
              <li>
                <img alt='' src='https://mispichos.com/public/landing/images/services-m.png' />
                <span>
                  Métricas
                </span>
                <p>
                  Te brindamos información sobre
                  productos en
                  tu zona para que nunca pierdas
                  una venta por falta de stock.
                </p>
              </li>
            </ol>
          </article>
        </div>
        <article className='bloqueSeis'>
          <h1>
            Preguntas frecuentes
          </h1>
          <ul>
            <li>
              <h4>
                ¿CÓMO SABE MISPICHOS.COM LOS PRODUCTOS QUE VENDO?
              </h4>
              <p>
                Vos indicás los productos que solés tener en stock. En caso de que no lo tengas cuando recibís la notificación de un nuevo pedido, podés rechazarla por falta de stock y es asignado a otro asociado.
              </p>
            </li>
            <li>
              <h4>
                ¿CÓMO SE FIJAN LOS PRECIOS DE LOS PRODUCTOS PUBLICADOS?
              </h4>
              <p>
                Los precios son fijados entre todos los asociados. Cada uno indica en su panel el precio al que vende los productos en su pet shop, de esa forma el sistema calcula el precio promedio, lo que posibilita que todos vendan al mismo precio.
              </p>
            </li>
            <li>
              <h4>
                ¿PUEDO DECIDIR LOS DÍAS Y HORARIOS EN QUE HAGO LAS ENTREGAS?
              </h4>
              <p>
                Sí. Vos decidís los días y rangos horarios de tus entregas, de esta forma sólo se te asignan pedidos que estén dentro de tu disponibilidad.
              </p>
            </li>
            <li>
              <h4>
                ¿QUIÉN HACE EL ENVÍO?
              </h4>
              <p>
                El pet shop entrega sus productos en el domicilio del cliente sin cargo adicional.
              </p>
            </li>
            <li>
              <h4>
                ¿CÓMO PAGO LA COMISIÓN A MISPICHOS?
              </h4>
              <p>
                Todos los lunes, se generan las liquidaciones del canon del servicio, correspondiente a todos los pedidos aceptados entre el lunes y domingo previo al día de liquidación. Ese importe es descontado, del monto de ventas cobrado mediante la plataforma.
              </p>
            </li>
            <li>
              <h4>
                ¿CUÁL ES EL COSTO DEL SERVICIO?
              </h4>
              <p>
                El Pet Shop paga el 9% + IVA de las ventas de alimentos balanceados realizadas a través de MisPichos.com. Sólo pagás por lo que vendes, sin costos de adhesión ni mantenimiento.
              </p>
            </li>
            <li>
              <h4>
                ¿CÓMO RECIBO EL DINERO DE MIS VENTAS?
              </h4>
              <p>
                Si el cliente paga en efectivo: Lo recibís en el momento de entregar el producto Si el cliente paga online: Lo recibís en tu cuenta de MercadoPago dentro del plazo de acreditación vigente en el momento (Consultarlo con nuestro asesor asignado a tu cuenta)
              </p>
            </li>
          </ul>
        </article>
        <div className='mapaVersatil'>
          <article className='bloqueSiete'>
            <ol>
              {/*<li>
                <FontAwesomeIcon icon={faPhoneAlt} />
                <span>+54 (011)5263-9573</span>
              </li>*/}
              <li>
                <FontAwesomeIcon icon={faEnvelope} />
                <span>vendedores@mispichos.com</span>
              </li>
              <li>
                <FontAwesomeIcon icon={faUser} />
                <span>www.mispichos.com/vendedores</span>
              </li>
              <li>
                <FontAwesomeIcon icon={faMapMarkerAlt} />
                <span>Gorriti 6046, of 801 (C1414BKN)<br />Ciudad de Buenos Aires</span>
              </li>
            </ol>
          </article>
          <article className='bloqueSiete'>
            <br />
            <div>
              <fieldset>
                <label htmlFor="name">nombre</label>
                <input type="text" placeholder='' id='name' />
              </fieldset>
              <fieldset>
                <label htmlFor="mail">mail</label>
                <input type="mail" placeholder='' id='mail' />
              </fieldset>
              <fieldset>
                <label htmlFor="tel">teléfono</label>
                <input type="text" placeholder='' id='tel' />
              </fieldset>
              <fieldset>
                <label htmlFor="dire">dirección</label>
                <input type="text" placeholder='' id='dire' />
              </fieldset>
            </div>
            <button>Quiero que me contacten</button>
          </article>
        </div>
        {/*
        <article className='bloqueOcho'>
          <button>
            <img src={require('../../IMG/ig.png')} alt=""/>
          </button>
          <button>
            <img src={require('../../IMG/fb.png')} alt=""/>
          </button>
          <button>
            <img src={require('../../IMG/tw.png')} alt=""/>
          </button>
        </article>
        */}
      </section>
    )
  }
}



export default withRouter(PreguntasFrecuentes)