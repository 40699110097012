import React from 'react';
import { withRouter } from "react-router-dom";
import OliverMap from '../Tools/OliverMap';
import { AlertSimple } from '../Tools/Alert';
import OliverHelper from '../../utils/Oliverhelper';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import { ButtonLoading } from '../Tools/Buttons';
import AnalitycEvents from '../../utils/AnalyticsTag';
import translate from '../../languages'
import { URL_NAMING } from '../Routes/Routes';

const Analitycs = new AnalitycEvents()
const country = process.env.REACT_APP_MP_COUNTRY;
var ActualCountry = null;
if (country === 'MLA') ActualCountry = "ar";
if (country === 'MLM') ActualCountry = "mx";

class NewAddressForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      activeButton: false,
      newAddressStep: 0,
      piso: '',
      dpto: '',
      address: '',
      logueado: false,
      loadingAddress: false,
      loadingScript: true,
      moveMap: false,
      typeAddress: '',
      userAddresses: [],
      addressList: [
        {
          addressKeyName: 'Casa',
        },
        {
          addressKeyName: 'Trabajo',
        },
        {
          addressKeyName: 'Otro',
        },
      ],
      addressStorage: {},
      domIdSelected: 0,
      newAddress: {},
      mapViewLocation: {},
      saveDirection: false,
      showAlert: false,
      alert: {
        type: 'Success',
        labelText: 'Perfecto!',
        secondLabel: 'Pudimos guardar la dirección',
      }
    };
    this.google = false;
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    document.addEventListener('didFocus', this.loadingGmapsScripts());
  }


  googleLoaded = () => {
    this.google = window.google;
    this.setState({ loadingScript: false });
  }

  loadingGmapsScripts = () => {
    const existingScript = document.getElementById('googleMaps');
    if (!existingScript) {
      //console.log('adding gmaps scripting');
      const script = document.createElement('script');
      let keyGamps = process.env.REACT_APP_GMAPS_KEY;
      script.src = `https://maps.googleapis.com/maps/api/js?key=${keyGamps}&libraries=places`;
      script.id = 'googleMaps';
      document.body.appendChild(script);
      script.onload = (google) => {
        this.googleLoaded();
      };

    } else {
      this.googleLoaded();
    }
  }

  redirect = () => {
    const location = this.props.location.state;
    const data = this.props.data;
    if (location !== undefined && location === "/mi-cuenta") {
      return this.props.history.push('/')
    } else if ((location === "individualProd" || data === "individualProd") && location !== "user" && location !== "/mi-cuenta") {
      this.props.history.push({ pathname: `/${URL_NAMING.ENVIO}`, state: { detail: "carrito" } });
    } else if (data === 'paymentMethod' || (location !== undefined && location === 'paymentMethod')) {
      if (window.location.pathname === '/carrito/para-pagar/') {
        this.props.history.push('/carrito/para-pagar/')
        return this.props.history.go()
      } else {
        return this.props.history.push('/carrito/para-pagar/')
      }
    } else if (location === "user") {
      this.props.history.push('/productos')
    }
    // Parametro redireccionar 
    else if (this.props.location.navigationBack) {
      this.props.history.push({ pathname: this.props.location.navigationBack, state: { card: this.props.location.cardData } });
    } else {
      window.location.reload();
    }
  }

  handleSubmit(event) {
    event.preventDefault();
  }

  handleChange = address => this.setState({ address });


  handleSelect = address => {
    geocodeByAddress(address)
      .then(results => {

        var geocoder = this.google.maps.Geocoder;

        console.log("DIRECCION GOOGLE: ", geocoder)

        var formatted = this.cargaCamposForm(results[0]);

        console.log('DIRECCION FORMATEADA', formatted, results[0]);

        // Validar Altura
        if (!formatted.altura) {
          // Modal Alerta
          var params = {
            cityLat: 19.4315409,
            cityLng: -99.1334425
          }
          this.props.showModalGeneric && this.props.showModalGeneric("No pudimos detectar tu altura en el mapa", "Ingresa una direccion cercana, indicarla manualmente en el mapa o ponte en contacto con nosotros");
          this.setState({ address: '', moveMap: true, newAddress: params });
          return;
        }

        var mapViewLocation = { latitude: results[0].geometry.location.lat(), latitudeDelta: 0.0095, longitude: results[0].geometry.location.lng(), longitudeDelta: 0.0095 }

        this.setState({
          newAddress: formatted,
          activeButton: true,
          address: address,
          domIdSelected: 0,
          mapViewLocation: mapViewLocation,
          newAddressStep: 1,
        });
      })
      .catch(error => console.error('Error', error));
  };


  cargaCamposForm = (place) => {
    var es_place = false;
    let domicilio = {};
    //Latitud y logitid
    domicilio.cityLat = place.geometry.location.lat();
    domicilio.cityLng = place.geometry.location.lng();
    for (var i = 0; i < place.address_components.length; i++) {
      var addressType = place.address_components[i].types[0];
      var value = place.address_components[i].short_name;
      if (addressType === "postal_code") {
        domicilio.cp = value;
      }
      if (addressType === "street_number") {
        domicilio.altura = value;
      }
      if (addressType === "sublocality_level_1" || addressType === "sublocality" || addressType === "political") {
        domicilio.barrio = value;
      }
      if (addressType === "route") {
        if (!es_place) {
          domicilio.calle = value;
        } else {
          domicilio.calle = place.name;
        }

      }
      if (addressType === "locality") {
        domicilio.localidad = place.name;
      }
      if (addressType === "administrative_area_level_2") {
        domicilio.partido = value;
      }
      if (addressType === "administrative_area_level_1") {
        value = place.address_components[i].long_name;
        var pos_borrar = value.toLowerCase().indexOf("provincia de ");
        if (pos_borrar !== -1) {
          value = value.substring(13);
        }
        domicilio.ciudad = value;
      }
      if (addressType === "country") {
        domicilio.pais = place.address_components[i].long_name;
      }
    }
    return domicilio;
  }

  geocoderByLat = (results) => {
    console.log('ENTRA', results);
    var formatted = this.cargaCamposForm(results);

    console.log('DIRECCION FORMATEADA', formatted, results);

    // Validar Altura
    if (!formatted.altura) {
      // Modal Alerta
      this.props.showModalGeneric && this.props.showModalGeneric("No pudimos detectar tu altura en el mapa", "Ingresa una direccion cercana o ponte en contacto con nosotros");
      this.setState({ address: '', moveMap: true });
      return;
    }

    var mapViewLocation = { latitude: results.geometry.location.lat(), latitudeDelta: 0.0095, longitude: results.geometry.location.lng(), longitudeDelta: 0.0095 }

    this.setState({
      newAddress: formatted,
      activeButton: true,
      address: results.formatted_address,
      domIdSelected: 0,
      mapViewLocation: mapViewLocation,
      newAddressStep: 1,
    });
  }



  sendAddress = () => {
    var domicilio = this.state.newAddress
    domicilio.key_name = this.state.typeAddress
    this.setState({ loadingAddress: true }, () => {
      OliverHelper.ClientAPI.postAddress({ piso: this.state.piso, dpto: this.state.dpto, address: domicilio }).then(async (response) => {
        //TODO falta evaluaciones desde que lugar esta mandando
        if (response && response.status && response.payload.domicilio !== undefined) {
          OliverHelper.setDomicilio(response.payload.domicilio);
          setTimeout(() => {
            Analitycs.gTagAddressSuccessful()
            this.setState({ showAlert: true, loadingAddress: false });
            this.redirect();
          }, 1000);
        } else {
          alert("No pudimos dar de alta tu dirección");
          this.setState({ loadingAddress: false });
        }
      })
    })

  }

  changePiso = (value) => {
    this.setState({ piso: value })
  }

  changeDepto = (value) => {
    this.setState({ dpto: value })
  }

  identifyPlace = (value) => {
    this.setState({ typeAddress: value })
  }



  render() {
    if (this.state.loadingScript || !this.google) return null;
    console.log("newAdres", this.state.newAddress)
    return (
      <section className='NewAddress'>
        <FormNewAddress
          handleSubmit={this.handleSubmit}
          googleObj={this.google}
          handleChange={this.handleChange}
          handleSelect={this.handleSelect}
          loadingAddress={this.state.loadingAddress}
          sendAddress={this.sendAddress}
          address={this.state.address}
          newAddress={this.state.newAddress}
          newAddressStep={this.state.newAddressStep}
          piso={this.state.piso}
          dpto={this.state.dpto}
          changePiso={this.changePiso}
          changeDepto={this.changeDepto}
          showAlert={this.state.showAlert}
          redirect={this.redirect}
          identifyPlace={this.identifyPlace}
          geocoderByLat={this.geocoderByLat}
          moveMap={this.state.moveMap}
        />
      </section>
    )
  }
}


export default withRouter(NewAddressForm);

const FormNewAddress = ({ moveMap, geocoderByLat, googleObj, identifyPlace, showAlert, redirect, changePiso, changeDepto, handleSubmit, handleChange, handleSelect, sendAddress, address, newAddress, newAddressStep, piso, dpto, ...props }) => {
  const google = googleObj;
  var defaultBounds = new google.maps.LatLngBounds(new google.maps.LatLng(-34.6037389, -58.3815704));
  var options = { types: ['establishment', 'geocode'], bounds: defaultBounds, componentRestrictions: { country: ActualCountry } };

  return (
    <form className='Card' onSubmit={(evt) => handleSubmit(evt)}>
      <h2>
        {translate.t('newAddress')}
      </h2>
      <fieldset>
        <label>
          {translate.t('street')}
        </label>
        <PlacesAutocomplete
          value={address}
          debounce={500}
          searchOptions={options}
          highlightFirstSuggestion={true}
          onChange={handleChange}
          onSelect={handleSelect}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div>
              <input
                {...getInputProps({
                  placeholder: 'Ingresa tu dirección',
                  className: 'location-search-input',
                })}
              />
              <div className="autocomplete-dropdown-container">
                {suggestions.map(suggestion => {
                  const className = 'suggestion-item--active'
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      </fieldset>
      <fieldset>
        <label>
          Piso y Departamento
        </label>
        <div className='RowCenter'>
          <input
            type="text"
            placeholder={'Piso'}
            value={piso}
            onChange={(piso) => changePiso(piso.target.value)}
          />
          <input
            type="text"
            placeholder={'Departamento'}
            value={dpto}
            onChange={(dpto) => changeDepto(dpto.target.value)}
          />
        </div>
      </fieldset>

      {(newAddressStep && piso === '') ?
        <AlertSimple
          type='Warning' /* Success / Danger dependiendo */
          labelText={translate.t('validateDepto')}
          secondLabel={'Completa el Piso y Depto, de ser asi.'}
          onPress={() => { console.log('Toco el Mensaje Alert') }}
        />
        : null}

      {/*
      <fieldset>
        <label>
          Identifica mejor este lugar.
        </label>
        <input
          type="text"
          placeholder={'Ejemplo: Mi casa...Mi trabajo..etc'}
          maxLength={16}
          onChange={(value) => identifyPlace(value.target.value)}
        />
      </fieldset>
    */}
      {newAddress.cityLat &&
        <fieldset className='Mapa' style={{ height: "200px", width: "100%" }}>
          <OliverMap lat={newAddress.cityLat} long={newAddress.cityLng} google={google} geocoderByLat={geocoderByLat} moveMap={moveMap} />
        </fieldset>

      }
      {newAddressStep && !showAlert ?
        <div className='FixedBottom'>

          <ButtonLoading
            title={'Confirmar dirección'}
            loading={props.loadingAddress}
            onClick={() => sendAddress()} />

        </div> : null}



      <div />
    </form>
  )

}