import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const zoho = window.$zoho;

class BannerImages extends Component {

  chatOpen = () => {
    zoho && zoho.salesiq.chat.start()
  }
  render() {
    return (
      <Carousel
        showArrows={false}
        showStatus={false}
        showIndicators={false}
        showThumbs={false}
        swipeable
        autoPlay={true}
        infiniteLoop={true}
        stopOnHover={false}
        onClickItem={i => {
          if (i === 2) {
            this.props.history.push('/politicas-envios');
          } else if (i === 3) {
            this.chatOpen();
          }
        }}
      >
        {/**<img alt="3m sin interes" src={require('../../IMG/banner_5.jpg')} /> */}
        <img alt="compra segura" src={require('../../IMG/banner_3.jpg')} />
        <img alt="envío gratis" src={require('../../IMG/banner_2.jpg')} />
        <img alt="chatea con nosotros" src={require('../../IMG/banner_4.jpg')} />
      </Carousel>
    );
  }
}

class BannerImagesDesk extends Component {
  render() {
    return (
      <div className='RowCenter BannerImagesDesk'>
        {/**<img alt="3m sin interes" src={require('../../IMG/banner_5.jpg')} /> */}
        <img alt="compra segura" src={require('../../IMG/banner_3.jpg')} />
        <img alt="envío gratis" onClick={() => this.props.history.push('/politicas-envios')} src={require('../../IMG/banner_2.jpg')} />
        <img alt="chatea con nosotros" onClick={() => zoho && zoho.salesiq.chat.start()} src={require('../../IMG/banner_4.jpg')} />
      </div>
    );
  }
}
const BannerImagesDeskRouter = withRouter(BannerImagesDesk);
export default withRouter(BannerImages);
export { BannerImagesDeskRouter }