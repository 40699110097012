import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { withRouter } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import OliverHelper from '../../utils/Oliverhelper';
import Modal from '../Tools/Modal';
import { MyImage } from '../Tools/Inputs';

class PetBanner extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      showLoginForm: false,
    }
  }

  toggleModal = () => this.setState({ showLoginForm: !this.state.showLoginForm })

  openNewPet = () => {
    let logueado = OliverHelper.loggedIn();
    if (logueado) {
      if (isMobile) {
        this.props.history.push('/alta-mascota/')
      } else {
        this.props.toggleDrawer('pet');
      }
    } else {
      if (isMobile) {
        this.props.history.push('/registro')
      } else {
        this.toggleModal()
      }
    }
  }

  render() {

    return (
      <div>
        <Modal closeModal={this.toggleModal} showModal={this.state.showLoginForm} type={'loginButton'} />
        <section className='PetBanner' id='AltaMascota'>

          <h3>¿Por qué elegir Oliver Pets?</h3>

          <div>
            <MyImage alt='alimentos' image={{ src: require('../../IMG/perrito_scaner_01.jpg'), class: '' }} />
          </div>
          <div>
            <img alt='' src={require('../../IMG/pelotitas.png')} />
            <button className='Btn-Action RowCenter p2' onClick={() => { this.openNewPet() }}>
              Agregar Mascota
              <FontAwesomeIcon icon={faArrowRight} />
            </button>
          </div>

        </section>
      </div>
    )
  }
}

export default withRouter(PetBanner)