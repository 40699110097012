import React, { Suspense } from 'react';

import PetBanner from '../Banners/PetBanner';
import AuthService from '../../utils/AuthService';
import { isMobile } from 'react-device-detect';
import { LoadingComponentHomeMobile, LoadingHomeBanner, LoadingPromotion } from '../Tools/LoadingSkeletons';
import { BannerImagesDeskRouter } from '../Tools/BannerImages';
import TheyTrustUs from '../Banners/TheyTrustUs';
import OliverHelper from '../../utils/Oliverhelper';
const Auth = new AuthService();

const PetCarousel = React.lazy(async () => await import('./PetCarousel'))
const BannerImages = React.lazy(async () => await import('../Tools/BannerImages'))
//const PetBanner = React.lazy(async () => await import('../Banners/PetBanner'))
const HomeBanner = React.lazy(async () => await import('../Banners/HomeBanner'))
const MobileCanvas = React.lazy(async () => await import('../Home/MobileCanvas'))
const HomeCanvas = React.lazy(async () => await import('./HomeCanvas'))
const BrandsContainer = React.lazy(async () => await import('./BrandsContainer'))
const CardBottom = React.lazy(async () => await import('../Tools/CardBottom'))

export default class Home extends React.Component {


  componentDidMount() {
    window.scrollTo(0, 0);
    OliverHelper.showPopupApp();
  }


  showCardBottom = () => {
    if (Auth.loggedIn()) {
      return <Suspense fallback={<div />}><CardBottom /></Suspense>
    } else { return null }
  }

  render() {
    //console.log('render home');
    document.title = 'Oliver pets: El Delivery De Tus Mascotas'
    return (
      <section className='BlueBox'>
        {isMobile ?
          <Suspense fallback={<LoadingHomeBanner />}>
            <HomeBanner />  {/*<LoadingHomeBanner />*/}
          </Suspense>
          : null}

        {isMobile ? null :
          <Suspense fallback={<div />}>
            <PetCarousel big={true} toggleDrawer={this.props.toggleDrawer} toggleModal={this.props.toggleModal} />
          </Suspense>
        }


        {!isMobile && <BannerImagesDeskRouter />}

        {isMobile ?
          <Suspense fallback={<LoadingComponentHomeMobile />}>
            <MobileCanvas />  { /* <LoadingComponentHomeMobile /> */}
          </Suspense>
          :
          <Suspense fallback={<div />}><HomeCanvas toggleDrawer={this.props.toggleDrawer} showDrawer={this.props.showDrawer} /></Suspense>
        }

        {isMobile &&
          <Suspense fallback={<LoadingPromotion />}>
            <BannerImages />
          </Suspense>
        }

        <Suspense fallback={<div />}><BrandsContainer />  </Suspense>

        <PetBanner toggleDrawer={this.props.toggleDrawer} />

        <TheyTrustUs />
        {isMobile ? this.showCardBottom() : null}
      </section>
    );
  }
}